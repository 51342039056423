function StandardButton(props) {
  let classname = '';
  let textStyle = '';
  if (!props.size || props.size === 'normal') {
    classname = 'text-white bg-kit-dark-1 p-2 rounded-md hover:bg-kit-dark-4';
  }
  if (props.size === 'big') {
    classname = 'text-white bg-kit-dark-1 p-8 rounded-md hover:bg-kit-dark-4';
  }
  if (props.size === 'span') {
    classname =
      'text-white bg-kit-dark-1 p-8 rounded-md hover:bg-kit-dark-4 w-full mb-5';
  }
  if (props.size === 'labelItem') {
    classname =
      'text-white bg-kit-dark-1 overflow-hidden rounded-md hover:bg-kit-dark-4 flex flex-col justify-center w-60 h-60';
    textStyle =
      'font-semibold no-underline text-white text-3xl text-center self-center align-middle';
  }
  if (props.size === 'cancel') {
    classname = 'text-white bg-red-700 hover:bg-red-800 p-8 rounded-md';
  }
  if (props.size === 'skuItem') {
    classname =
      'text-white bg-kit-dark-1 overflow-hidden rounded-md hover:bg-kit-dark-4 flex flex-col justify-center w-80 h-40';
    textStyle =
      'font-semibold no-underline text-white text-2xl text-center self-center align-middle';
  }

  return (
    <button
      onClick={props.onClick}
      className={`font-bold text-xl ${classname} ${textStyle}`}
    >
      {props.children}
    </button>
  );
}

export default StandardButton;
