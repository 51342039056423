import React from "react";

function DefaultInput(props) {
  return (
    <input
      className="bg-kit-light-2"
      onChange={props.handleChange}
      type="text"
      value={props.val}
    ></input>
  );
}

export default DefaultInput;
