import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useState } from "react";
import { useMutation } from "react-query";
import Loading from "../components/loading";
import StandardButton from "../components/standardButton";

function AssetsNum() {
  const [assetTagType, setAssetTagType] = useState("BDO-5A-RO-BY");
  const [startFrom, setStartFrom] = useState("");
  const [amount, setAmount] = useState("0");

  const BACKEND = process.env.REACT_APP_SERVER_URL;
  const { getAccessTokenSilently, user } = useAuth0();
  const userRoles = user['kit/roles'];
  const location = userRoles.find(role => role === "warehouse-sfo") ? "SFO" : "PHX";

  const labelMutation = useMutation(async ({ e }) => {
    e.preventDefault();
    const body = {
      assetTagType: assetTagType,
      startFrom: startFrom,
      location: location,
      amount: amount,
    };

    const token = await getAccessTokenSilently();

    const res = await axios.post(`${BACKEND}/assets_num`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(res.data);
    return res.data;
  });

  return (
    <div className="h-screen overflow-hidden">
      <div className="h-full flex flex-col justify-around">
        <form className="w-6/12 self-center bg-kit-light-2 rounded-xl p-8">
          <div>
            <h2 className="text-xl">Print Asset Tags - Sequential Number</h2>
          </div>
          <div className="flex h-full flex-col justify-around">
            <div>
              <label htmlFor="assetTagType">
                <h3>Asset Tag Type</h3>
                <select
                  onChange={e => setAssetTagType(e.target.value)}
                  id="assetTagType"
                  className="w-full"
                >
                  <option value="BDO-5A-RO-BY">BDO-5A-RO-BY</option>
                  <option value="BDO-MONITOR-RO-TT">BDO-MONITOR-RO-TT</option>
                  <option value="BDO-106-RO-ED">BDO-106-RO-ED</option>
                  <option value="BDO-2/2A-RO-TT">BDO-2/2A-RO-TT</option>
                </select>
              </label>
            </div>
            <div>
              <label htmlFor="startFrom">
                <h3>Start From</h3>
                <input
                  type="text"
                  onChange={e => setStartFrom(e.target.value)}
                  id="startFrom"
                  className="w-full"
                ></input>
              </label>
              <label htmlFor="amount">
                <h3>Amount (1-1000)</h3>
                <input
                  type="text"
                  onChange={e => setAmount(e.target.value)}
                  id="amount"
                  className="w-full"
                ></input>
              </label>
            </div>
            <div>
              <div>
                <StandardButton onClick={(e) => labelMutation.mutate({ e })}>
                  Print Asset Tags
                </StandardButton>
                <div>{labelMutation.isLoading ? <Loading /> : ""}</div>
                <div>
                  {labelMutation.isError ? (
                    <div>
                      <div>Error</div>
                      <div>{labelMutation.error?.response?.data?.code}</div>
                      <div>{labelMutation.error?.response?.data?.name}</div>
                      <div>
                        {labelMutation.error?.response?.data?.description}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {labelMutation.isSuccess ? (
                    <>
                      <div>Success!</div>
                    </>
                  ) : ""}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AssetsNum;
