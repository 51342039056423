import { useParams } from 'react-router-dom';
import { useMedplum, useResource } from '@medplum/react';
import { Stack, Affix, Anchor } from '@mantine/core';
import { useEffect, useState } from 'react';
import { getReferenceString } from '@medplum/core';

import { ActionItems } from './actionItems';

export function ResultsView({ role }) {
  const { id } = useParams();
  const medplum = useMedplum();
  const serviceRequest = useResource({ reference: 'ServiceRequest/' + id });
  const [tasks, setTasks] = useState();

  useEffect(() => {
    if (serviceRequest) {
      medplum
        .searchResources(
          'Task',
          `based-on=${getReferenceString(serviceRequest)}&status:not=completed`
        )
        .then((e) => {
          return e || null;
        })
        .then(setTasks)
        .catch(console.error);
    }
  }, [id, medplum, serviceRequest, tasks]);

  return (
    <>
      <ActionItems tasks={tasks} role={role} />

      <Affix position={{ left: 50, top: 50 }}>
        <Stack>
          <Anchor href={`/${role}/pending-repairs`}>Back</Anchor>
        </Stack>
      </Affix>
    </>
  );
}
