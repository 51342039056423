import { Button, Loader } from '@mantine/core';
import {
  getCodeBySystem,
  getIdentifier,
  MedplumClient,
  ProfileResource,
} from '@medplum/core';
import { Identifier, ServiceRequest } from '@medplum/fhirtypes';
import { Document, useMedplum, useMedplumProfile } from '@medplum/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import '../styles/warehouse-fulfillment.css';

const BACKEND = process.env.REACT_APP_SERVER_URL;
const BARCODE_SYSTEM = 'kit/barcodeId';
const SECONDARY_ID_SYSTEM = 'kit/secondaryId';
const REFRESH_INTERVAL = 60000;
const WAREHOUSE_OUTGOING_STATUS = 'WAREHOUSE_OUTGOING';
const SKU_SYSTEM = 'https://kit.com/sku';
const KITTYPE_SYSTEM = 'https://kit.com/kitType';
const WAREHOUSE_MAP: { [key: string]: string } = {
  CA: 'warehouse-sfo',
  AZ: 'warehouse-phx',
};
// Date format in YYYY-MM-DD
const DATE_OF_TODAY = new Date().toISOString().slice(0, 10);

export default function WarehouseFulfillment() {
  // Set up React Hooks
  // const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const medplum = useMedplum();
  const profile = useMedplumProfile();
  const [orders, setOrders] = useState<ServiceRequest[]>();
  const [displayedOrders, setDisplayedOrders] = useState<ServiceRequest[]>();
  const [filteredSku, setFilteredSku] = useState<string>('ALL');
  const [total, setTotal] = useState<number>();
  const profileRef = useRef<ProfileResource | undefined>();
  const curWarehouses = new Set(
    profile?.address
      ?.filter(
        (adr) =>
          adr.use === 'work' &&
          adr.type === 'physical' &&
          (adr.state || '') in WAREHOUSE_MAP
      )
      .map((adr) => adr.state && WAREHOUSE_MAP[adr.state])
  );
  profileRef.current = profile;

  const [countDailyShipped, setCountDailyShipped] = React.useState<number>(0);

  // Refresh data every on a timer
  useEffect(() => {
    loadOrders();
    countShippedFromAZ().then((res) =>
      res ? setCountDailyShipped(res) : null
    );
    const timer = setInterval(loadOrders, REFRESH_INTERVAL);

    return () => clearInterval(timer);
  }, [profile]);

  useEffect(() => {
    loadDisplayedOrders();
  }, [orders, filteredSku]);

  const saveButton = useMemo(
    () => (
      <span className={'save-button-span'}>
        <Button onClick={handleSave}>Save</Button>
      </span>
    ),
    []
  );

  if (medplum.isLoading()) {
    return null;
  }

  // // Not signed in
  // if (!profile) {
  //   return (
  //     <MedplumSignIn />
  //   );
  // }

  if (!displayedOrders) {
    return <Loader size="xl" />;
  }
  return (
    <Document>
      <div className="medplum-center">
        <h1>Kit Warehouse Orders</h1>
        <div>
          <div className="content-container">
            <div>
              <p>Total Outgoing today (AZ): {countDailyShipped}</p>
              <label>Filter by SKU: </label>
              <select
                name="filter-by-sku"
                id="filter-by-sku"
                onChange={(e) => setFilteredSku(e.target.value)}
              >
                <option value="ALL">All</option>
                <option value="BDO-5A-RO-BY">BDO-5A-RO-BY</option>
                <option value="BDO-2/2A-RO-TT">BDO-2/2A-RO-TT</option>
                <option value="BDO-MONITOR-RO-TT">BDO-MONITOR-RO-TT</option>
                <option value="BDO-106-RO-ED">BDO-106-RO-ED</option>
              </select>
              {saveButton}
              <Footer
                medplum={medplum}
                pageLength={displayedOrders.length}
                totalOrders={total || 0}
                refreshFunction={loadOrders}
              />
              <table>
                <thead>
                  <tr>
                    <th>Patient</th>
                    <th>Carrier</th>
                    <th>Code</th>
                    <th>Kit Type</th>
                    <th>Date/Time</th>
                    <th>Status</th>
                    <th>Barcode</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {displayedOrders.map((order, index) => (
                    <tr className={getRowClass(order)} key={order.id}>
                      <td>
                        <a
                          href={`https://app.medplum.com/ServiceRequest/${order.id}`}
                        >
                          {order.subject?.display}
                        </a>
                      </td>
                      <td>{order.category?.[0]?.text}</td>
                      <td>{getCodeFromSystem(order, SKU_SYSTEM)}</td>
                      <td>{getCodeFromSystem(order, KITTYPE_SYSTEM)}</td>
                      <td>{formatDate(order.meta?.lastUpdated)}</td>
                      <td>{order.orderDetail?.[0]?.text}</td>
                      <td>
                        <input
                          type="text"
                          placeholder="barcode"
                          data-order-id={order.id}
                          autoFocus={index === 0}
                          onKeyDown={handleEnterKey}
                          onBlur={handleBlur}
                          id={`barcode-${order.id}`}
                          defaultValue={getIdentifier(order, BARCODE_SYSTEM)}
                        />
                      </td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {saveButton}
            </div>
          </div>
        </div>
        <Footer
          medplum={medplum}
          pageLength={displayedOrders.length}
          totalOrders={total || 0}
          refreshFunction={loadOrders}
        />
      </div>
    </Document>
  );

  async function loadDisplayedOrders() {
    if (filteredSku === 'ALL') {
      setDisplayedOrders(orders);
    } else {
      setDisplayedOrders(
        orders.filter((order) => order.code.coding[0].code === filteredSku)
      );
    }
  }

  async function countShippedFromAZ() {
    const dailyShippedFromAZParams = new URLSearchParams({
      'order-detail': 'WAREHOUSE_OUTGOING',
      _lastUpdated: `gt${DATE_OF_TODAY}T08:00:00.000Z`,
      _total: 'accurate',
      _count: '0',
    });
    const countOrdersShipped = await medplum.search(
      'ServiceRequest',
      dailyShippedFromAZParams.toString()
    );
    return countOrdersShipped.total;
  }

  async function loadOrders(): Promise<void> {
    // Make sure that we are logged in
    const profile = profileRef.current;
    if (!profile) {
      return;
    }

    var allOrders: ServiceRequest[] = [];

    for await (const page of medplum.searchResourcePages('ServiceRequest', {
      'order-detail': 'CUSTOMER_ORDERED',
      _lastUpdated: 'gt2022-06-01T00:00:00.000Z',
      _sort: '_lastUpdated',
      _count: 100,
    })) {
      allOrders.push(...page);
    }

    console.log('allOrders: ', allOrders);

    const curFilteredOrders = allOrders.filter((sr) => {
      let tagging = 'warehouse-sfo'; // Set a default tagging in case no tagging found in the order

      for (let detail of sr.orderDetail || []) {
        let curTagging = getCodeBySystem(detail, 'tagging');

        if (curTagging) {
          tagging = curTagging;
          break;
        }
      }

      return curWarehouses.has(tagging);
    });

    // Compare the new orders to existing ones to see if any new orders have come in
    const oldOrderIds = new Set(orders?.map((e) => e.id));
    const curOrderIds = new Set(curFilteredOrders?.map((e) => e.id));
    const newOrderIds = [...curOrderIds].filter((e) => !oldOrderIds.has(e));

    // Optional - beep when new orders come in
    if (newOrderIds.length > 0) {
      console.debug(newOrderIds);
    }

    setOrders(curFilteredOrders);
    setTotal(curFilteredOrders.length);
  }

  /* Event Handlers */

  /**
   * When the user hits ENTER, progress the cursor to the next text input.
   * If the cursor is on the last input, blur all inputs.
   * */
  function handleEnterKey(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key.toLowerCase() === 'enter') {
      const target = event.target as HTMLInputElement;
      const inputNodes: HTMLInputElement[] = Array.from(
        document.getElementsByTagName('input')
      );
      const nodeIndex = inputNodes.indexOf(target);

      if (nodeIndex < inputNodes.length - 1) {
        inputNodes[nodeIndex + 1].focus();
      } else {
        target.blur();
      }
      event.preventDefault();
    }
  }

  /**
   * Then the cursor leaves an input field, strip the prefix "PBK" (provided by the barcode scanner)
   *
   *  */
  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement;
    let barcode = target.value;
    if (barcode.match(/pbk[0-9]+/gi)) {
      barcode = barcode.substring(3);
      target.value = barcode;
    }
    if (barcode.length > 6) {
      target.value = barcode.substring(0, 6);
    }
  }

  /**
   * Save orders with their barcode ids.
   */
  async function handleSave() {
    const auth0Token = await getAccessTokenSilently();
    const inputNodes: HTMLInputElement[] = Array.from(
      document.getElementsByTagName('input')
    );

    // Prevent accidentally input duplicate barcodes
    const inputNodesNonEmpty = inputNodes.filter(
      (node) => node.value.trim().length > 0
    );
    const inputBarcodes = inputNodesNonEmpty.map((node) => node.value.trim());
    const inputBarcodesSet = new Set(inputBarcodes);
    if (inputBarcodes.length !== inputBarcodesSet.size) {
      window.alert('Found duplicate input barcodes!');
      return { outcome: 'ERROR' };
    }

    // Send out POST requests to save updated orders
    const savePromises = inputNodes.map(async (node: HTMLInputElement) => {
      const barcode = node.value.trim();
      const orderId = node.dataset.orderId;
      const result = {
        orderId: orderId,
        barcode: barcode,
        patientName: '',
        outcome: '',
      };
      if (!orderId) {
        result.outcome = 'ERROR';
        return result;
      }

      if (barcode === '') {
        result.outcome = 'NO-OP';
        return result;
      }

      try {
        const updatedOrder = await saveOrderBarcode(
          medplum,
          orderId!,
          barcode,
          auth0Token
        );
        result.patientName = updatedOrder.subject!.display!;
        result.outcome = 'SUCCESS';
      } catch (err) {
        result.outcome = 'ERROR';
      } finally {
        return result;
      }
    });

    // Create success/fail messages for each order
    Promise.all(savePromises)
      .then((saveResults) => {
        const messages = saveResults.map((result) => {
          if (result?.outcome === 'ERROR') {
            return `${result.outcome}: ${result.orderId}`;
          } else if (result?.outcome === 'SUCCESS') {
            return `${result.outcome}: ${result.patientName} => "${result.barcode}"`;
          }
        });
        if (messages.length > 0) {
          window.alert(messages.join('\n'));
        }
      })
      // No matter what happens, reload the orders
      .finally(() => {
        loadOrders();
      });
  }
}

async function saveOrderBarcode(
  medplum: MedplumClient,
  orderId: string,
  barcode: string,
  auth0Token: string
) {
  const sameBarcodeOrders = await medplum.searchResources(
    'ServiceRequest',
    `identifier:exact=${barcode}`
  );
  // If found another order with the same barcode in Medplum, pop up an alert
  if (sameBarcodeOrders !== undefined && sameBarcodeOrders.length > 0) {
    for (const sameOrder of sameBarcodeOrders) {
      if (sameOrder.id !== orderId) {
        window.alert('Found an existing barcode in Medplum!');
      }
    }
    return Promise.reject();
  }

  const currentOrder = await medplum.readResource('ServiceRequest', orderId);

  const res = await axios.get(`${BACKEND}/secondary_id?barcodeId=${barcode}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth0Token}`,
    },
  });

  if (res.status !== 200) {
    window.alert('Failed to get secondary id');
    return Promise.reject();
  }

  const secondaryId = res.data.asset.secondary_id;
  console.log('Secondary ID: ' + secondaryId);

  // If there is already a barcode, replace it with the new barcode
  const existingBarcodeIndex = currentOrder.identifier?.findIndex(
    (identifier: Identifier) => identifier.system === BARCODE_SYSTEM
  );

  const existingSecondaryIdIndex = currentOrder.identifier?.findIndex(
    (identifier: Identifier) => identifier.system === SECONDARY_ID_SYSTEM
  );

  if (existingBarcodeIndex !== undefined && existingBarcodeIndex >= 0) {
    currentOrder.identifier![existingBarcodeIndex] = {
      system: BARCODE_SYSTEM,
      value: barcode,
    };

    if (
      existingSecondaryIdIndex !== undefined &&
      existingSecondaryIdIndex >= 0
    ) {
      currentOrder.identifier![existingSecondaryIdIndex] = {
        system: SECONDARY_ID_SYSTEM,
        value: secondaryId,
      };
    } else {
      currentOrder.identifier!.push({
        system: SECONDARY_ID_SYSTEM,
        value: secondaryId,
      });
    }
  } else {
    // Otherwise, add a new Barcode identifier
    if (!currentOrder.identifier?.[0]) {
      currentOrder.identifier = [];
    }
    currentOrder.identifier.push({
      system: BARCODE_SYSTEM,
      value: barcode,
    });
    currentOrder.identifier.push({
      system: SECONDARY_ID_SYSTEM,
      value: secondaryId,
    });
  }

  // Set the order status to OUTGOING
  currentOrder.orderDetail![0].text = WAREHOUSE_OUTGOING_STATUS;
  return medplum.updateResource(currentOrder);
}

/* Data Utilities */
function formatDate(dateTime: string | undefined): string {
  return dateTime ? new Date(dateTime).toLocaleString() : '';
}

/* Highlight rows according to SKU to make it easier for the Warehouse team to see */
function getRowClass(order: ServiceRequest): string {
  const orderCode = order.code?.coding?.[0]?.code?.toUpperCase();
  if (orderCode && orderCode === 'BDO-5A-RO-BY') {
    return 'color-BDO-5A-RO-BY';
  } else if (orderCode && orderCode === 'BDO-2/2A-RO-TT') {
    return 'color-BDO-2-2A-RO-TT';
  } else if (orderCode && orderCode === 'BDO-MONITOR-RO-TT') {
    return 'color-BDO-MONITOR-RO-TT';
  }
  return '';
}

function getCodeFromSystem(order: ServiceRequest, system: string): string {
  const coding = order.code?.coding;
  if (coding === undefined) {
    return '';
  }
  for (const element of coding) {
    if (element.system === system) {
      return element.code || '';
    }
  }
  return '';
}

/* React Sub-Components */

interface FooterProps {
  medplum: MedplumClient;
  pageLength: number;
  totalOrders: number;
  refreshFunction: () => void;
}

function Footer({
  medplum,
  pageLength,
  totalOrders,
  refreshFunction,
}: FooterProps): JSX.Element {
  return (
    <div>
      <p>
        Showing {pageLength} / {totalOrders} order(s) &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="#" onClick={refreshFunction}>
          Refresh
        </a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="#" onClick={() => medplum.signOut()}>
          Sign out
        </a>
      </p>
    </div>
  );
}
