import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import BarcodeInput from '../components/barcodeInput';
import Loading from '../components/loading';
import StandardButton from '../components/standardButton';
import { parse_name, medplumUrl, parse_datetime } from '../utils';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';

const BACKEND = process.env.REACT_APP_SERVER_URL;

function SelectBox(props) {
  return (
    <div className="h-screen flex flex-col justify-evenly">
      <div>
        <h1 className="text-4xl text-center">
          Scan a Box ID to retrieve its data
        </h1>
      </div>
      <BarcodeInput
        onScan={props.onScan}
        placeholder="Or enter box ID manually here"
        ignoreSecondId={true}
      />
    </div>
  );
}

function daysBetween(before, after) {
  return Math.floor((after.getTime() - before.getTime()) / (1000 * 3600 * 24));
}

function BoxAccession(props) {
  const [skuData, setSkuData] = useState([]);
  const [cameraStarted, setCameraStarted] = useState(false);
  const [photoCaptured, setPhotoCaptured] = useState(false);
  const [photoUploading, setPhotoUploading] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState([]);
  const [updateStatus, setUpdateStatus] = useState({});
  const [collection, setCollection] = useState([]);
  const [tubeIds, setTubeIds] = useState([]);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function fetchData() {
      console.log(props.labData);

      // alert if state is ['NY']
      if (props.labData.patient?.address?.state === 'NY') {
        alert('ALERT: Patient has a NY state');
      }

      const token = await getAccessTokenSilently();
      try {
        if (props.labData.sku) {
          const { data } = await axios.get(
            `${BACKEND}/panel_assays?sku=${props.labData.sku}`,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (data && data[0] && data[0]['panels']) {
            setSkuData(data[0]['panels']);
          }
        }
      } catch (error) {
        console.log('error fetching SKU data: ', error);
      }

      var specimens = props.labData.specimens;
      if (specimens[0]) {
        const response = await axios.get(
          `${BACKEND}/lab_data?specimen_id=${specimens[0]}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const date = response.data.collection?.collectedDateTime;
        if (date === undefined) {
          alert(
            'The collection date for this order is missing, please add it in using the Medplum interface!'
          );
        } else {
          setCollection((prev) => [...prev, date]);
          // calculate stability, alert if needed
          const collectionDate = new Date(date);
          const currentTime = new Date();
          const stability = daysBetween(collectionDate, currentTime);
          const sku = props.labData.sku;
          if (
            sku &&
            (sku === 'BDO-5A-RO-BY' || sku === 'BDO-106-RO-ED') &&
            stability > 3
          ) {
            alert(`ALERT: Sample has stability of ${stability} days!`);
          }
        }

        setTubeIds((prev) => [
          ...prev,
          response.data.accessionIdentifier.value,
        ]);
      }
      if (specimens[1]) {
        const response = await axios.get(
          `${BACKEND}/lab_data?specimen_id=${specimens[1]}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const date = response.data.collection?.collectedDateTime;
        if (date === undefined) {
          alert(
            'The collection date for this order is missing, please add it in using the Medplum interface! [DAY 2]'
          );
        } else {
          setCollection((prev) => [...prev, date]);
        }
        setTubeIds((prev) => [
          ...prev,
          response.data.accessionIdentifier.value,
        ]);
      }
    }
    fetchData();
  }, [
    props.labData,
    getAccessTokenSilently,
    cameraStarted,
    photoCaptured,
    photoUploading,
    confirmStatus,
  ]);

  async function startCamera() {
    setCameraStarted(true);
    var video = document.getElementById('video');
    try {
      var stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });
      video.srcObject = stream;
      video.play();
    } catch (error) {
      console.log('An error occured when starting camera: ', error);
    }
  }

  function takePhoto() {
    var canvas = document.getElementById('canvas');
    var video = document.getElementById('video');
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    setPhotoCaptured(true);
    setConfirmStatus([]);
  }

  function uploadPhoto() {
    var canvas = document.getElementById('canvas');
    canvas.toBlob(async (blob) => {
      setPhotoUploading(true);
      var data = new FormData();
      var fileName = `-${Date.now()}.png`;
      data.append('boxId', props.labData.barcodeId);
      data.append('file', blob, fileName);
      const token = await getAccessTokenSilently();
      try {
        await axios.post(`${BACKEND}/lab_data`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        setConfirmStatus([1, 'Successfully uploaded photo to Medplum!']);
      } catch (err) {
        setConfirmStatus([0, `Failed to upload photo to Medplum: ${err}`]);
      } finally {
        setPhotoUploading(false);
      }
    }, 'image/png');
  }

  function getStateColor(state) {
    var className =
      'text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r';
    if (['NY', 'MD'].includes(state)) {
      return className + ' bg-gray-400';
    } else {
      return className;
    }
  }

  function getCollectionColor(collection) {
    var className =
      'text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r';
    return collection.length === 0 ? className + ' bg-red-400' : className;
  }

  async function updateMedplumState(status) {
    setUpdateStatus({ status: 202 });
    const body = {
      orderId: props.labData.id,
      status: status,
    };
    const token = await getAccessTokenSilently();
    const response = await axios.put(`${BACKEND}/lab_data`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    setUpdateStatus(response);
    return response;
  }

  function UpdateToLabAccessioned() {
    const medplumStatus = 'LAB_ACCESSIONED';

    return (
      <div>
        <StandardButton
          onClick={() => updateMedplumState(medplumStatus)}
          size="big"
        >
          Lab Accessioned
        </StandardButton>
        {Object.keys(updateStatus).length === 0 ? (
          ''
        ) : updateStatus.status === 200 ? (
          <div className="text-green-600 pt-5 font-bold">Success!</div>
        ) : updateStatus.status === 202 ? (
          <Loading></Loading>
        ) : (
          <div>Failed! Please retry.</div>
        )}
      </div>
    );
  }

  async function printLabels() {
    const labData = props.labData;
    const labelNeededOnSku = {
      'BDO-2-RO-TT/1': 3,
      'BDO-7-SZN-BD': 3,
      'BDO-2-KIT-BD': 5,
      'BDO-5-RO-BY': 5,
      'BDO-104-VRTA-GN': 5,
      'BDO-2/2A-RO-TT': 5,
      'BDO-106-RO-ED': 5,
      'BDO-2B-RO-TT': 5,
      'BDO-5A-RO-BY': 5,
    };
    // Info needed to be presented on the labels
    const barcodeId = labData.barcodeId;
    const patientName = labData.patient?.name;
    const sku = labData.sku;
    const patientBirthDate = labData.patient?.birthDate;

    const browserPrint = props.browserPrint.current;
    // Check if the printer is ready
    const printerStatus = await browserPrint.checkPrinterStatus();
    if (printerStatus.isReadyToPrint) {
      // ZPL script to print a label
      const zpl = `
                ^XA
                ^FO65,50^BY2
                ^BCN,40,Y,N,N,A
                ^FD${barcodeId}^FS
                ^FO35,130^A0N36,20
                ^FD${patientBirthDate}^FS
                ^FO35,155^A0N36,20
                ^FD${sku}^FS
                ^FO35,180^A0N36,20
                ^FD${patientName}^FS
                ^XZ`;
      const labelRequired =
        labelNeededOnSku[sku] !== undefined ? labelNeededOnSku[sku] : 1;
      for (let i = 0; i < labelRequired; i++) {
        browserPrint.write(zpl);
      }
    } else {
      console.log('Error/s', printerStatus.errors);
    }
  }

  return (
    <div className="h-screen pt-10 px-10">
      {props.labData.messages?.map((message) => {
        return (
          <div className="max-w-sm rounded overflow-hidden shadow-lg">
            <div className="px-6 py-4 bg-blue-300">
              <div className="font-bold text-xl mb-2">{message.author}</div>
              <p className="text-gray-700 text-base">{message.message}</p>
            </div>
          </div>
        );
      })}

      <div className="flex flex-col h-64">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <table className="min-w-full border text-center">
              <thead className="border-b">
                <tr className="bg-white">
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                  >
                    Patient Name
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                  >
                    Date of Birth
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                  >
                    State
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                  >
                    Collection Date/Time
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                  >
                    Insurance Carrier
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                  >
                    Box ID
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                  >
                    Tube(s) ID
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                  >
                    Secondary ID
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                  >
                    SKU
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                  >
                    Kit Type
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b">
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                    {props.labData.patient?.name !== undefined ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={medplumUrl(props.labData.id)}
                      >
                        {parse_name(props.labData.patient.name)}
                      </a>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                    {props.labData.patient?.birthDate}
                  </td>
                  <td
                    className={getStateColor(
                      props.labData.patient?.address?.state
                    )}
                  >
                    {props.labData.patient?.address?.state}
                  </td>
                  <td className={getCollectionColor(collection)}>
                    {collection.length === 0
                      ? ''
                      : collection.map((collect) => {
                          return (
                            <div>
                              {parse_datetime(collect)}
                              <br />
                            </div>
                          );
                        })}
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                    {props.labData.insuranceCarrierName}
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                    {props.labData.barcodeId}
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                    {tubeIds.map((tubeId) => {
                      return (
                        <div>
                          <li key={tubeId}>{tubeId}</li>
                        </div>
                      );
                    })}
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                    {props.labData.secondaryId.map((id) => {
                      return (
                        <div>
                          <li key={id}>{id}</li>
                        </div>
                      );
                    })}
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                    {props.labData.sku}
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                    {props.labData.kitType}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="flex flex-col pt-10 h-72">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <table className="min-w-full border text-center">
              <thead className="border-b">
                <tr className="bg-white">
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                  >
                    Panel Description
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                  >
                    Assays List
                  </th>
                </tr>
              </thead>
              {skuData?.map((sku) => {
                return (
                  <tbody>
                    <tr className="bg-white border-b">
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                        {sku.description}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                        {sku.assays?.map((assay) => {
                          return <div key={assay.id}>{assay.id}</div>;
                        })}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
      </div>
      {/* <div className="flex flex-row py-10"> */}
      <div className="py-10">
        <div className="px-10 space-x-10 flex flew-row">
          {!cameraStarted ? (
            <>
              <div>
                <StandardButton onClick={() => startCamera()} size="big">
                  Upload Photo
                </StandardButton>
              </div>
              {<UpdateToLabAccessioned />}
              <div>
                <StandardButton onClick={() => printLabels()} size="big">
                  Print Labels
                </StandardButton>
              </div>
            </>
          ) : (
            ''
          )}

          <video id="video" width="640" height="480" autoPlay></video>

          {cameraStarted ? (
            <div className="pt-3">
              <StandardButton onClick={() => takePhoto()} size="big">
                Take Photo
              </StandardButton>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="px-10 flew-col">
          <canvas id="canvas" width="640" height="480"></canvas>
          {photoCaptured ? (
            photoUploading ? (
              <div>
                <Loading></Loading>
              </div>
            ) : confirmStatus.length > 0 ? (
              <div>
                {confirmStatus[0] === 1 ? (
                  <div>
                    <p className="text-green-600 pt-5 font-bold">
                      {confirmStatus[1]}
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="text-red-600 pt-5 font-bold">
                      {confirmStatus[1]}
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div className="pt-3">
                <StandardButton onClick={() => uploadPhoto()} size="big">
                  Upload Photo
                </StandardButton>
              </div>
            )
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

function LabData() {
  const [workflowState, setWorkflowState] = useState('PRODUCT_TYPES');
  const [labData, setLabData] = useState({});
  const browserPrint = useRef({});

  useEffect(() => {
    async function initBrowserPrint() {
      browserPrint.current = new ZebraBrowserPrintWrapper();
      const defaultPrinter = await browserPrint.current.getDefaultPrinter();
      browserPrint.current.setPrinter(defaultPrinter);
    }
    initBrowserPrint();
  }, []);

  const { getAccessTokenSilently } = useAuth0();

  const getBox = async (boxId) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${BACKEND}/lab_data?box_id=${boxId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setLabData(response.data);
      setWorkflowState('BOX_ACCESSION');
    } catch (err) {
      console.log(err.response);
      alert(err.response.data);
    }
  };

  let component = null;

  if (workflowState === 'PRODUCT_TYPES') {
    component = <SelectBox onScan={getBox} />;
  } else if (workflowState === 'BOX_ACCESSION') {
    component = (
      <div>
        <StandardButton
          onClick={() => setWorkflowState('PRODUCT_TYPES')}
          size="span"
        >
          Back
        </StandardButton>
        <BoxAccession labData={labData} browserPrint={browserPrint} />
      </div>
    );
  }

  return <div>{component}</div>;
}

export default LabData;
