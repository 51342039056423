import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useState } from "react";
import { useMutation } from "react-query";
import Loading from "../components/loading";
import StandardButton from "../components/standardButton";

function AssignUdi() {
  const [barcodeId, setBarcodeId] = useState("");
  const [udi, setUdi] = useState("");

  const BACKEND = process.env.REACT_APP_SERVER_URL;
  const { getAccessTokenSilently } = useAuth0();

  const labelMutation = useMutation(async ({ e }) => {
    e.preventDefault();
    const body = {
      barcodeId: barcodeId,
      udi: udi,
    };

    const token = await getAccessTokenSilently();

    const res = await axios.post(`${BACKEND}/assign_udi`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  });

  const handleInputChange = (e) => {
    let barcode = e.target.value;
    if (e.target.value.length >= 6) {
      e.target.value = barcode.substring(0,6);
    }
    setBarcodeId(e.target.value.replace("PBK", ""));
  };

  return (
    <div className="h-screen overflow-hidden">
      <div className="h-full flex flex-col justify-around">
        <form className="w-6/12 self-center bg-kit-light-2 rounded-xl p-8"
          onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}>
          <div>
            <h2 className="text-xl">Assign a UDI Label</h2>
          </div>
          <div className="flex h-full flex-col justify-around">
            <div>
              <label htmlFor="barcodeId">
                <h3>Barcode ID</h3>
                <input
                  type="text"
                  onChange={handleInputChange}
                  value={barcodeId}
                  id="barcodeId"
                  className="w-full"
                  autoFocus
                ></input>
              </label>
            </div>
            <div>
              <label htmlFor="udi">
                <h3>udi</h3>
                <input
                  type="udi"
                  onChange={e => setUdi(e.target.value)}
                  id="udi"
                  className="w-full"
                ></input>
              </label>
            </div>
            <div>
              <div>
                <StandardButton onClick={(e) => labelMutation.mutate({ e })}>
                  Assign UDI Label
                </StandardButton>
                <div>{labelMutation.isLoading ? <Loading /> : ""}</div>
                <div>
                  {labelMutation.isError ? (
                    <div>
                      <div>Error</div>
                      <div>{labelMutation.error?.response?.data?.code}</div>
                      <div>{labelMutation.error?.response?.data?.name}</div>
                      <div>
                        {labelMutation.error?.response?.data?.description}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {labelMutation.data ? (
                    <>
                      <div>Success!</div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AssignUdi;
