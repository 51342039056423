import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Unauthorized from "../views/unauthorized";
import LoadingPage from "../views/loadingPage";
import "../App.css";

const ProtectedRoute = ({ component, allowedRoles, ...args }) => {
  const { user } = useAuth0();
  const userRoles = user['kit/roles'];

  return (
    // user['kit/roles'] is a customized role setup in auth0
    userRoles.find(role => allowedRoles?.includes(role))
      ?
      <Route
        component={withAuthenticationRequired(component, {
          onRedirecting: () => <LoadingPage />,
        })}
        {...args}
      />
      : <Unauthorized />
  );
};

export default ProtectedRoute;
