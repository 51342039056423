import axios from "axios";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import BarcodeInput from "../components/barcodeInput";
import LabeledQRCode from "../components/labeledQRCode";
import OrderTable from "../components/orderTable";
import { useAuth0 } from "@auth0/auth0-react";
import { parse_name, playNotification } from "../utils";
import StandardButton from "../components/standardButton";

const BACKEND = process.env.REACT_APP_SERVER_URL;

function OrdersList(props) {
  const { user } = useAuth0();
  const userRoles = user['kit/roles'];
  let orders = props.orders || [];
  let total = props.total;

  if(userRoles.find(role => role === "warehouse-phx")) {
    orders = props.orders?.filter(order => order.insuranceCarrierName === "Ro");
  }

  return (
    <div className="flex flex-col content-center justify-around h-screen">
      <div>
        <h1 className="text-2xl text-center">Scan an order to assign to a box.</h1>
      </div>
      <div>
        <h2 className="text-xl text-center">
          There are {total} orders remaining
        </h2>
      </div>
      <div className="h-4/6 self-center overflow-y-scroll w-11/12 rounded-xl">
        <OrderTable orders={orders} />
      </div>
      <div>
        <BarcodeInput onScan={props.onScan} placeholder="Or enter orderId manually here" />
      </div>
      <div className="self-center ">
        <StandardButton size="normal" onClick={() => playNotification()}>
          Test Audio
        </StandardButton>
      </div>

    </div>
  );
}

function OrderConfirmed(props) {
  return (
    <div className="h-screen flex flex-col justify-around">
      <div>
        <h1 className="text-center text-2xl">
          Order was assigned successfully.
        </h1>
        {
          props.shippingLabels.length !== 0 ? (
            <div className="text-center text-2xl">
              <div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={props.shippingLabels[0]}
                >
                  label
                </a>
                </div>
                <div>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={props.shippingLabels[1]}
                >
                  return label
                </a>
              </div>
            </div>
          ) : ("")
        }
      </div>
      <div className="flex justify-around">
        <LabeledQRCode size={128} value="OK" />
      </div>
      <BarcodeInput onScan={props.onScan} />
    </div>
  );
}

function OrderCanceled(props) {
  return (
    <div className="h-screen flex flex-col justify-around">
      <div>
        <h1 className="text-center text-2xl">Order was not assigned.</h1>
      </div>
      <div className="flex justify-around">
        <LabeledQRCode size={128} value="OK" />
      </div>
      <BarcodeInput onScan={props.onScan} />
    </div>
  );
}

function AssignOrder(props) {
  return (
    <div className="h-screen flex flex-col justify-around">
      <div className="text-center">
        <div className="py-8">
          <h1 className="text-2xl">
            Assigning a box: {parse_name(props?.order?.patient?.name)}<br></br>
            Order Box Type: {props?.order?.kitType}
          </h1>
        </div>
        <div className="py-8">
          <h2 className="text-xl">{parse_name(props?.order?.patient?.name)}</h2>
          <h2 className="text-xl">{props?.order?.patient?.address?.line1}</h2>
          <h2 className="text-xl">{props?.order?.patient?.address?.city}</h2>
          <h2 className="text-xl">{props?.order?.patient?.address?.state}</h2>
          <h2 className="text-xl">
            {props?.order?.patient?.address?.postalCode}
          </h2>
        </div>

        <div className="py-8">
          {props.box.isIdle ? (
            <div>
              <div>Waiting for box to be scanned...</div>
            </div>
          ) : (
            ""
          )}
          {props.box.isError ? (
            <div>
              <div>Error</div>
              <div>{props.box.error?.response?.data?.code}</div>
              <div>{props.box.error?.response?.data?.name}</div>
              <div>{props.box.error?.response?.data?.description}</div>{" "}
            </div>
          ) : (
            ""
          )}
          {props.box.data ? (
            <div>
              <div>Assigning this order to boxId {props.box.data.id}</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="flex justify-around">
        <LabeledQRCode size={128} value="CANCEL" />
        {props.box.data ? (
          <div>
            <LabeledQRCode size={128} value="CONFIRM" />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="h-1/6">
        <BarcodeInput onScan={props.onScan} placeholder="Or enter box ID manually here" />
      </div>
    </div>
  );
}
function Orders(props) {
  const [workflowState, setWorkflowState] = useState("ORDERS_LIST");
  const [selectedBox, setSelectedBox] = useState({});
  const [selectedOrder, setSelectedOrder] = useState({});
  const [prevOrders, setPrevOrders] = useState([]);
  const [shippingLabels, setShippingLabels] = useState([]);

  const { getAccessTokenSilently } = useAuth0();

  const orders = useQuery(
    "orders",
    async () => {
      const token = await getAccessTokenSilently();
      const res = await axios.get(`${BACKEND}/orders`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      // if there's a new order id in the response, play a notification:

      // hash against orderIds for easy lookup
      const prevOrderIds = prevOrders.map((order) => order.id);
      const prevOrderHash = {};
      prevOrderIds.forEach((orderId) => {
        prevOrderHash[orderId] = true;
      });

      let notify = false;
      res.data.orders.forEach((order) => {
        if (!prevOrderHash[order.id]) {
          notify = true;
        }
      });

      if (notify) {
        playNotification();
      }


      setPrevOrders(res.data.orders);
      return res.data;
    },
    {
      refetchInterval: 1000 * 30,
      refetchIntervalInBackground: true,
    }
  );

  function onScanOk(barcodeId) {
    if (barcodeId === "OK") {
      setSelectedBox({});
      setSelectedOrder({});
      setWorkflowState("ORDERS_LIST");
    }
  }

  const boxMutation = useMutation(
    async (orderId) => {
      const token = await getAccessTokenSilently();
      if (orderId === "CANCEL") {
        console.log("CANCEL");
        setWorkflowState("CANCELED");
        return {};
      }
      if (orderId === "CONFIRM") {
        const body = {
          boxId: selectedBox.id,
          barcode_id: selectedBox.id,
          boxTypeVersion: selectedBox.product,
          shippingType: "UPS"
        };
        await axios.put(`${BACKEND}/orders?id=${selectedOrder.id}`, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then(async () => {
            await axios.get(`${BACKEND}/box_assets?box_id=${selectedBox.id}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              }
            })
              .then(async response => {
                const asset_ids = response.data.map(data => data.asset_id);
                const body = {
                  asset_ids: asset_ids,
                  increment: true
                };
                await axios.put(`${BACKEND}/assets`, body, {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  }
                });
              })
              .catch(err => {
                console.log(err);
              });
          })

        const res = await axios.post(`${BACKEND}/shipping_label`, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        });
        //console.log(res);
        setShippingLabels([res.data.label, res.data.returnLabel]);
        setWorkflowState("CONFIRMED");
        return {};
      }

      const res = await axios.get(`${BACKEND}/boxes?id=${orderId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    },
    {
      onSuccess: (data) => {
        setSelectedBox(data);
      },
    }
  );

  const orderMutation = useMutation(
    async (orderId) => {
      const token = await getAccessTokenSilently();
      const res = await axios.get(`${BACKEND}/orders?id=${orderId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const lockBody = {
        orderId: orderId,
      };

      await axios.post(`${BACKEND}/order_locks`, lockBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    },
    {
      onSuccess: (data) => {
        setSelectedOrder(data);
        setWorkflowState("ASSIGN_ORDERS");
      },
      onError: (data) => {
        alert("Can't find this order");
        console.error("collision");
      },
    }
  );

  let component = null;
  if (workflowState === "ORDERS_LIST") {
    if (orders.isLoading) {
      component = <div>loading</div>;
    }
    if (orders.data) {
      component = (
        <OrdersList total={orders.data.total} orders={orders.data.orders} onScan={orderMutation.mutate} />
      );
    }
  } else if (workflowState === "ASSIGN_ORDERS") {
    if (orderMutation.isLoading) {
      component = <div>loading</div>;
    }
    if (orderMutation.data) {
      component = (
        <AssignOrder
          box={boxMutation}
          order={orderMutation.data}
          onScan={boxMutation.mutate}
        />
      );
    }
  } else if (workflowState === "CANCELED") {
    component = <OrderCanceled onScan={onScanOk} />;
  } else if (workflowState === "CONFIRMED") {
    component = <OrderConfirmed onScan={onScanOk} shippingLabels={shippingLabels} />;
  }

  return (
    <>
      <div>{component}</div>
    </>
  );
}

export default Orders;
