import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import Loading from "../components/loading";

const BACKEND = process.env.REACT_APP_SERVER_URL;

function SkuList() {
    const { getAccessTokenSilently } = useAuth0();

    const { data, status } = useQuery(
        "skuList",
        async () => {
            const token = await getAccessTokenSilently();
            const res = await axios.get(
                `${BACKEND}/panel_assays`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log(res.data);
            return res.data;
        }
    );

    function Sku(props) {
        return(
            props.data.map(sku => {
                return(
                    <div key={sku.id}>
                        <h1 className="text-xl font-bold">{sku.id}</h1>
                        <h2>{sku.description}</h2>
                        <hr className="mb-3" style={{height: "3px", background: "black"}}></hr>
                        {sku.panels.map(panel => {
                            return(
                                <div>
                                    <ul className="list-disc list-inside">
                                    <li key={panel.id} className="underline">{panel.id} ({panel.description})</li>
                                    <ul className="list-disc list-inside pl-8" style={{listStyleType: "circle"}}>
                                    {panel.assays.map(assay => {
                                        return (
                                            
                                                <li key={assay.id}>{assay.id}</li>
                                        )
                                    })}
                                    </ul>
                                    </ul>
                                </div>
                            )
                        })}
                        <br></br>
                    </div>
                    
                )
            })
        );
    }

    return (
        <div className="h-max overflow-hidden">
            <div className="flex flex-col justify-around">
                <div className="w-8/12 self-center">
                    <h1 className="text-center text-2xl font-bold">SKU - Panels - Assays Relation</h1>
                    <div>{ status === "loading" ? <Loading /> : "" }</div>
                    <div>{ status === "success" ? <Sku data={data}/> : "" }</div>
                </div>
            </div>
        </div>
    );
}

export default SkuList;
