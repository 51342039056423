import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import BarcodeInput from "../components/barcodeInput";
import LabeledQRCode from "../components/labeledQRCode";
import { useAuth0 } from "@auth0/auth0-react";
import BoxAssignmentTable from "../components/boxAssignmentTable";

const BACKEND = process.env.REACT_APP_SERVER_URL;

function SelectBox(props) {
  return (
    <div className="h-screen flex flex-col justify-evenly">
      <div>
        <h1 className="text-4xl text-center">Please scan a box to assign to required assets.</h1>
      </div>
        <BarcodeInput onScan={props.onScan} placeholder="Or enter box ID manually here"/>
    </div>
  );
}

function BoxConfirmed(props) {
  return (
    <div className="h-screen flex flex-col justify-around">
      <div>
        <h1 className="text-center text-2xl">
          Assets were successfully assigned to the box.
        </h1>
      </div>
      <div className="flex justify-around">
        <LabeledQRCode size={128} value="OK" />
      </div>
      <BarcodeInput onScan={props.onScan} />
    </div>
  );
}

function BoxCanceled(props) {
  return (
    <div className="h-screen flex flex-col justify-around">
      <div>
        <h1 className="text-center text-2xl">
          Assets were not assigned to the box.
        </h1>
      </div>
      <div className="flex justify-around">
        <LabeledQRCode size={128} value="OK" />
      </div>
      <BarcodeInput onScan={props.onScan} />
    </div>
  );
}

function BoxAssignment(props) {
  //const boxKitType = props.kitTypes.find((kt) => kt.name === props.box.product);
  const labelingAssets = props.labelingAssets.map((asset) => asset.description);

  return (
    <div className="h-screen flex flex-col space-y-10">
      <div>
        <h1 className="text-center text-2xl">Box id: {props.box.id}</h1>
        <h1 className="text-center text-2xl">Type: {props.box.product}</h1>
        <h2 className="text-center text-xl">
          Please scan the appropriate assets to connect them to the box.
        </h2>
      </div>
      
      {labelingAssets ? (
        <div className="self-center overflow-y-scroll w-11/12 rounded-md">
          <BoxAssignmentTable
            requiredAssets={labelingAssets}
            assignedAssets={props?.assignedAssets}
          />
        </div>
      ) : null}
      <div className="flex justify-around">
        <LabeledQRCode size={128} value="CANCEL" />
        {labelingAssets?.length ===  props?.assignedAssets.length ? (
            <LabeledQRCode size={128} value="CONFIRM" />
          ) : (
            ""
          )}
      </div>
      <div>
        <BarcodeInput onScan={props.onScan} placeholder="Or enter asset id manually here"/>
      </div>
    </div>
  );
}

function Boxes() {
  //const [productType, setProductType] = useState("");
  const [assetList, setAssetList] = useState([]);
  const [workflowState, setWorkflowState] = useState("PRODUCT_TYPES");
  const [selectedBox, setSelectedBox] = useState({});
  const [kitTypes, setKitTypes] = useState([]);
  const [labelingAssets, setLabelingAssets] = useState([]);

  const getKitTypes = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${BACKEND}/kit_types`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  useEffect(async () => {
    const res = await getKitTypes();
    setKitTypes(res.data?.kit_types);
  }, []);

  const { getAccessTokenSilently } = useAuth0();
  // function onProductTypeScan(barcodeId) {
  //   setProductType(barcodeId);
  //   setWorkflowState("BOX_ASSIGNMENT");
  // }

  const getBox = async (boxId) => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${BACKEND}/boxes?id=${boxId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    setSelectedBox(response.data);

    const boxVersion = response.data.product;
    const result = await axios.get(`${BACKEND}/box_type_version_components?id=${boxVersion}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    setLabelingAssets(result.data);

    setWorkflowState("BOX_ASSIGNMENT");
  };

  const assetListMutation = useMutation(
    async (assetId) => {
      const token = await getAccessTokenSilently();
      if (assetId === "CANCEL") {
        setWorkflowState("CANCELED");
        return;
      }
      if (assetId === "CONFIRM") {
        const box = selectedBox;
        const body = { box: box, assets: assetList };
        await axios.put(`${BACKEND}/boxes`, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        setWorkflowState("CONFIRMED");
        return;
      }
      try {
        const res = await axios.get(`${BACKEND}/assets?id=${assetId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
  
        return res.data;
      } catch (err) {
        alert(err.response.data.message);
        console.log(err.response.data.message);
      }
      
    },
    {
      onSuccess: (data) => {
        // const product = selectedBox.product;
        // const type = kitTypes.find((kt) => kt.name === product);
        // let counts = {};
        // // hashmap of each type's key and the number of times it appears in the asset list
        // type.asset_list.forEach((asset) => {
        //   counts[asset] = (counts[asset] || 0) + 1;
        // });
        // // count of the asset scanned
        // const count = counts[data.category];

        // // counts of the keys in assetList
        // let assetListCounts = {};
        // assetList.forEach((asset) => {
        //   assetListCounts[asset.category] =
        //     (assetListCounts[asset.category] || 0) + 1;
        // });

        // // if the count of the asset scanned is less than the count of the asset in the asset list, add it to the asset list
        // if (count >= assetListCounts[data.category]) {
        //   console.warn(
        //     "Asset of that type exceeds the number of assets of that type"
        //   );
        //   return;
        // }
        if (!labelingAssets.map(a => a.description).includes(data.category)) {
          console.warn("Asset not required");
          return;
        }

        // if the scanned asset's id is already in the asset list, return early
        if (assetList.map((a) => a.id).includes(data.id)) {
          console.warn("Asset already in list");
          return;
        }

        const assets = [...assetList, data];
        setAssetList(assets);
      },
    }
  );

  function onScanOk(barcodeId) {
    if (barcodeId === "OK") {
      //setProductType("");
      setAssetList([]);
      setWorkflowState("PRODUCT_TYPES");
    }
  }

  let component = null;

  if (workflowState === "PRODUCT_TYPES") {
    component = <SelectBox onScan={getBox} />;
  } else if (selectedBox !== "" && workflowState === "BOX_ASSIGNMENT") {
    component = (
      <BoxAssignment
        box={selectedBox}
        assignedAssets={assetList}
        onScan={assetListMutation.mutate}
        kitTypes={kitTypes}
        labelingAssets={labelingAssets}
      />
    );
  } else if (workflowState === "CANCELED") {
    component = <BoxCanceled data={assetList} onScan={onScanOk} />;
  } else if (workflowState === "CONFIRMED") {
    component = <BoxConfirmed data={assetList} onScan={onScanOk} />;
  }

  return <div>{component}</div>;
}

export default Boxes;
