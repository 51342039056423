import React from "react";

function MenuItem(props) {
  return (
    <div className="overflow-hidden hover:bg-kit-light-4 flex flex-col justify-center w-48 h-56 bg-kit-light-2 rounded-xl">
      <h2 className="font-semibold no-underline text-kit-dark-1 text-3xl text-center self-center align-middle">
        {props.title}
      </h2>
    </div>
  );
}

export default MenuItem;
