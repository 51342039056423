import notificationSound from "./assets/notification.mp3";

export function medplumUrl(orderId) {
  return `https://app.medplum.com/ServiceRequest/${orderId}`;
}

export function playNotification() {
  const audio = new Audio(notificationSound);
  audio.volume = 0.75;
  audio.play();
}

export function parse_name(name) {
  if (name === undefined) {
    return
  }
  if (name.includes("^")) {
    const tokens = name.split("^");
    const first_name = tokens[1]
    const last_name = tokens[0]
    return first_name + " " + last_name
  }
  let newName = name.replace(/_/g , " ");
  newName = newName.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
  return newName;
}

export function parse_datetime(datetime) {
  const dateObject = new Date(datetime);
  return dateObject.toLocaleString();
}