import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import useDebounce from "../hooks/useDebounce";
import { medplumUrl } from "../utils";
import DefaultInput from "./defaultInput";

function PatientInput(props) {
  const BACKEND = process.env.REACT_APP_SERVER_URL;
  const { getAccessTokenSilently } = useAuth0();

  const debouncedVal = useDebounce(props.val);

  const order = useQuery(
    ["barcodeId", debouncedVal],
    async () => {
      if (!props.val) {
        return null;
      }
      const token = await getAccessTokenSilently();
      const res = await axios.get(
        `${BACKEND}/orders?barcodeId=${debouncedVal}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    },
    {
      enabled: true,
    }
  );

  // function handleChange(e) {
  //   console.log("patientinput");
  //   props.handleChange(e);
  //   if (e.target.value.length === 6 || e.target.value.length === 12) {
  //     patientMutation.mutate({ e });
  //   }
  // }

  return (
    <div className="flex flex-col justify-around">
      <h2 className="text-center text-xl">Enter a barcodeId</h2>
      <DefaultInput handleChange={props.handleChange} val={props.val} />
      <div className="text-center">
        {order.data ? (
          <div className="py-8">
            <h2 className="text-xl">
              <a
                target="_blank"
                rel="noreferrer"
                href={medplumUrl(order?.data?.id)}
              >
                medplum url
              </a>
            </h2>
            <h2 className="text-xl">{order?.data?.patient?.name}</h2>
            <h2 className="text-xl">{order?.data?.patient?.address?.line1}</h2>
            <h2 className="text-xl">{order?.data?.patient?.address?.city}</h2>
            <h2 className="text-xl">{order?.data?.patient?.address?.state}</h2>
            <h2 className="text-xl">
              {order?.data?.patient?.address?.postalCode}
            </h2>
            <h2 className="text-xl">{order?.data?.patient?.email}</h2>
          </div>
        ) : null}
        <div>
          <div>
            {order.isError ? (
              <div>
                <div>Error</div>
                <div>{order.error?.response?.data?.code}</div>
                <div>{order.error?.response?.data?.name}</div>
                <div>{order.error?.response?.data?.description}</div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientInput;
