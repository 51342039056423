import { AgGridReact } from 'ag-grid-react';
import { useMedplum } from '@medplum/react';
import { Button } from '@mantine/core';
import React, { forwardRef } from 'react';
import '../../src/styles.scss';

/*
This is main component that displays the Pending Orders Dashboard
*/
const PendingDashboard = forwardRef((props, ref) => {
  const {
    rows,
    columns,
    defaultColDef,
    onCellEditRequest,
    suppressRowClickSelection,
    onSelectionChanged,
    rowSelection,
  } = props;

  const medplum = useMedplum();

  return (
    <div style={{ height: '100%' }}>
      <Button className="my-2" onClick={() => medplum.signOut()}>
        Sign Out
      </Button>
      <div style={{ height: '800px', padding: '1px' }}>
        <div
          className="ag-theme-alpine"
          style={{ width: '100%', height: '95%' }}
        >
          <AgGridReact
            ref={ref}
            rowData={rows}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            readOnlyEdit={true}
            onCellEditRequest={onCellEditRequest}
            rowSelection={rowSelection}
            suppressRowClickSelection={suppressRowClickSelection}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
      </div>
    </div>
  );
});

export default PendingDashboard;
