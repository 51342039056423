import React from 'react';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import MenuItem from '../components/menuItem';
import Assets from './assets';
import Boxes from './boxes';
import Orders from './orders';
import ShippingLabels from './shippingLabels';
import BulkUdi from './bulk-udi';
import AssignUdi from './assign-udi';
import OrageneBarcode from './oragene-barcode';
import AssetsNum from './assets-num';
import OverflowConfiguration from './overflow-configuration';
import StateConfiguration from './state-configuration';
import MedplumSignIn from './medplumSignIn';
import { MedplumClient } from '@medplum/core';
import { MedplumProvider } from '@medplum/react';
import { MantineProvider } from '@mantine/core';
import WarehouseFulfillment from './warehouse-fulfillment';

function Warehouse() {
  const medplum = new MedplumClient({ autoBatchTime: 500, cacheTime: 60000 });

  return (
    <BrowserRouter basename="/warehouse">
      <Switch>
        <Route path="/" exact>
          <div className="h-screen overflow-hidden">
            <h1 className="text-4xl font-bold p-8">Welcome</h1>
            <div className="flex flex-row flex-wrap justify-start items-center gap-8 p-8">
              <Link className="no-underline" to="/assets">
                <MenuItem title="Assets" />
              </Link>
              <Link className="no-underline" to="boxes">
                <MenuItem title="Boxes" />
              </Link>
              <Link className="no-underline" to="orders">
                <MenuItem title="Orders" />
              </Link>
              <Link className="no-underline" to="shipping-labels">
                <MenuItem title="Shipping Labels" />
              </Link>
              <Link className="no-underline" to="bulk-udi">
                <MenuItem title="Print Bulk UDI Labels" />
              </Link>
              <Link className="no-underline" to="assign-udi">
                <MenuItem title="Assign a UDI Label" />
              </Link>
              {/* Non critical: Built for another customer does not apply to Ro. */}
              {/* <Link className="no-underline" to="oragene-barcode">
                <MenuItem title="Oragene Barcode" />
              </Link> */}
              <Link className="no-underline" to="overflow-configuration">
                <MenuItem title="Overflow Configuration" />
              </Link>
              <Link className="no-underline" to="state-configuration">
                <MenuItem title="State Configuration" />
              </Link>
              <Link className="no-underline" to="assets-num">
                <MenuItem title="Print Asset Tags (Sequential)" />
              </Link>
              <Link className="no-underline" to="warehouse-fulfillment">
                <MenuItem title="Warehouse Fulfillment Tool" />
              </Link>
            </div>
          </div>
        </Route>
        <Route path="/assets" exact>
          <Assets />
        </Route>
        <Route path="/boxes" exact>
          <Boxes />
        </Route>
        <Route path="/orders" exact>
          <Orders />
        </Route>
        <Route path="/shipping-labels" exact>
          <ShippingLabels />
        </Route>
        <Route path="/bulk-udi" exact>
          <BulkUdi />
        </Route>
        <Route path="/assign-udi" exact>
          <AssignUdi />
        </Route>
        <Route path="/oragene-barcode" exact>
          <OrageneBarcode />
        </Route>
        <Route path="/assets-num" exact>
          <AssetsNum />
        </Route>
        <Route path="/overflow-configuration" exact>
          <OverflowConfiguration />
        </Route>
        <Route path="/state-configuration" exact>
          <StateConfiguration />
        </Route>
        <MedplumProvider medplum={medplum}>
          <MantineProvider withGlobalStyles withNormalizeCSS>
            <Route path="/warehouse-fulfillment" exact>
              <MedplumSignIn component={WarehouseFulfillment} role="lab" />
            </Route>
          </MantineProvider>
        </MedplumProvider>
      </Switch>
    </BrowserRouter>
  );
}

export default Warehouse;
