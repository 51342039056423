import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import Loading from '../components/loading';
import StandardButton from '../components/standardButton';

function currentDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month}-${day}`;
}

function get_bartender_parameters(dateObject) {
  const DAYS_OF_EXPIRE = 81;
  const gtin = '00850046025037';
  const reference = 'bdo_lh_tc';
  const today = new Date();
  const expiry = new Date(dateObject);
  expiry.setDate(expiry.getDate() + DAYS_OF_EXPIRE);
  const year = expiry.getFullYear().toString().substring(2);
  const month = (expiry.getMonth() + 1).toString().padStart(2, '0');
  const day = expiry.getDate();
  const expiryCode = `${year}${month}${day}`;
  const expiryDate = expiry.toLocaleDateString('en-CA');
  const lot = expiryCode + '02';
  const serialNumber = today.getTime();
  return {
    serialNumber: serialNumber,
    lot: lot,
    reference: reference,
    expiryCode: expiryCode,
    expiryDate: expiryDate,
    gtin: gtin,
  };
}

function send_bartender_requests(amount, dateObject) {
  let callCount = 0;

  const makePostRequest = async () => {
    try {
      const bartenderRes = await axios({
        method: 'post',
        url: 'http://localhost/Integration/printUDI/Execute',
        timeout: 8000,
        headers: { 'Content-Type': 'text/plain' },
        data: get_bartender_parameters(dateObject),
      });

      callCount++;

      if (callCount === amount) {
        clearInterval(interval); // Stop the interval after 10 calls
      }
    } catch (error) {
      console.error(error);
      callCount++;

      if (callCount === amount) {
        clearInterval(interval); // Stop the interval after 10 calls
      }
    }
  };

  const interval = setInterval(makePostRequest, 1000);
}

function BulkUdi() {
  const [udiType, setUdiType] = useState('bdo_lh_hi');
  const [date, setDate] = useState(currentDate());
  const [dateObject, setDateObject] = useState(new Date());
  const [amount, setAmount] = useState('');
  // serial ?

  const BACKEND = process.env.REACT_APP_SERVER_URL;
  const { getAccessTokenSilently, user } = useAuth0();
  const userRoles = user['kit/roles'];
  const location = userRoles.find((role) => role === 'warehouse-sfo')
    ? 'SFO'
    : 'PHX';

  const labelMutation = useMutation(async ({ e }) => {
    e.preventDefault();
    const body = {
      udiType: udiType,
      // YYYY-MM-DD
      date: date,
      amount: amount,
      location: location,
    };

    const token = await getAccessTokenSilently();

    send_bartender_requests(parseInt(amount), dateObject);

    const res = await axios.post(`${BACKEND}/bulk_udi`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  });

  return (
    <div className="h-screen overflow-hidden">
      <div className="h-full flex flex-col justify-around">
        <form className="w-6/12 self-center bg-kit-light-2 rounded-xl p-8">
          <div>
            <h2 className="text-xl">Create UDI Labels</h2>
          </div>
          <div className="flex h-full flex-col justify-around">
            <div>
              <label htmlFor="udiType">
                <h3>UDI Type</h3>
                <select
                  onChange={(e) => setUdiType(e.target.value)}
                  id="udiType"
                  className="w-full"
                >
                  <option value="bdo_lh_hi">bdo_lh_hi</option>
                  <option value="bdo_lh_hi_new">
                    bdo_lh_hi (no stroop wafle)
                  </option>
                  <option value="bdo_tt">bdo_tt</option>
                  <option value="bdo_tt_new">bdo_tt (no stroop wafle)</option>
                  <option value="SAL-DNA">SAL-DNA</option>
                  <option value="BDO-EDTA-LG">BDO-EDTA-LG</option>
                  <option value="BDO-EDTA-LG-NEW">
                    BDO-EDTA-LG (no stroop wafle)
                  </option>
                  <option value="BDO-LH-TC">BDO-LH-TC</option>
                </select>
              </label>
            </div>
            <div>
              <label htmlFor="date">
                <h3>Date</h3>
                <input
                  type="date"
                  defaultValue={`${currentDate()}`}
                  onChange={(e) => {
                    setDateObject(e.target.valueAsDate);
                    setDate(e.target.value);
                  }}
                  id="date"
                  className="w-full"
                ></input>
              </label>
            </div>
            <div>
              <label htmlFor="amount">
                <h3>Amount</h3>
                <input
                  type="text"
                  onChange={(e) => setAmount(e.target.value)}
                  id="amount"
                  className="w-full"
                  value={amount}
                ></input>
              </label>
            </div>
            <div>
              <div>
                <StandardButton onClick={(e) => labelMutation.mutate({ e })}>
                  Make UDI Labels
                </StandardButton>
                <div>{labelMutation.isLoading ? <Loading /> : ''}</div>
                <div>
                  {labelMutation.isError ? (
                    <div>
                      <div>Error</div>
                      <div>{labelMutation.error?.response?.data?.code}</div>
                      <div>{labelMutation.error?.response?.data?.name}</div>
                      <div>
                        {labelMutation.error?.response?.data?.description}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  {labelMutation.data ? (
                    <>
                      <div>Success!</div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BulkUdi;
