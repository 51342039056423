import PendingDashboard from '../components/pendingDashboard';
import DatePicker from '../components/date-picker';
import React, { useEffect } from 'react';
import { getIdentifier, getReferenceString } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import { Link } from 'react-router-dom';

const REFRESH_INTERVAL = 10000;

// Search Parameters for getting relevant orders
const ORDER_SEARCH_PARAMS = new URLSearchParams({
  'order-detail': 'NEEDS_REPAIR',
  _count: '1000',
  _sort: '-_lastUpdated',
});

// Static columns for the output display table
const TABLE_COLUMNS = [
  {
    field: 'patient',
    headerName: 'Patient',
    cellRenderer: renderPatient,
    width: 150,
  },
  {
    field: 'barcode',
    headerName: 'Barcode',
    width: 100,
    filter: 'agNumberColumnFilter',
  },
  { field: 'tubeId', headerName: 'Tube ID', width: 60 },
  { field: 'carrier', headerName: 'Carrier', width: 50 },
  { field: 'sku', headerName: 'SKU' },
  {
    field: 'state',
    headerName: 'State',
    width: 50,
    cellStyle: getStateStyle,
  },
  {
    field: 'collected',
    headerName: 'Collected',
    valueFormatter: formatDateTime,
    filter: 'agDateColumnFilter',
    cellEditor: DatePicker,
    width: 130,
  },
  {
    field: 'received',
    headerName: 'Received',
    valueFormatter: formatDateTime,
    filter: 'agDateColumnFilter',
    cellEditor: DatePicker,
  },
  { field: 'stability', headerName: 'Stability', width: 70 },
  {
    field: 'lab',
    headerName: 'Lab',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset'],
      suppressAndOrCondition: true,
    },
    width: 80,
  },
  {
    field: 'details',
    headerName: 'Details',
    cellRenderer: renderDetails,
    autoHeight: true,
    width: 350,
  },
];

// Default values for column styling
const DEFAULT_COL_DEFS = {
  resizable: true,
  sortable: true,
  width: 100,
  wrapText: true,
  enableCellChangeFlash: true,
  cellStyle: {
    fontSize: '11px',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
};

export default function ValidationTable({ role }) {
  const medplum = useMedplum();

  // Refresh the page every 1 hr
  useEffect(() => {
    const timer = setInterval(
      window.location.reload.bind(window.location),
      3600000
    );
    return () => {
      clearInterval(timer);
    };
  }, []);

  // Fetch row data every few seconds
  const [rows, setRows] = React.useState();
  useEffect(() => {
    async function fetchAndSetRows() {
      fetchOrderData(medplum).then((orderData) =>
        setRows(getRowData(orderData, role))
      );
    }
    fetchOrderData(medplum).then((orderData) =>
      setRows(getRowData(orderData, role))
    );
    const timer = setInterval(fetchAndSetRows, REFRESH_INTERVAL);
    return () => {
      clearInterval(timer);
    };
  }, [medplum, role]);
  return (
    <PendingDashboard
      rows={rows}
      columns={TABLE_COLUMNS}
      columnDefs={TABLE_COLUMNS}
      defaultColDef={DEFAULT_COL_DEFS}
    />
  );
}

/*** Data Fetching  ***/

// Fetch all data related to each lab order from the server
async function fetchOrderData(medplum) {
  // First, we need to invalidate the caches to make sure we get fresh data
  medplum.invalidateSearches('ServiceRequest');

  // Fetch all the lab orders (i.e. ServiceRequests) to match the criteria in ORDER_SEARCH_PARAMS
  const requests = await medplum.searchResources(
    'ServiceRequest',
    ORDER_SEARCH_PARAMS.toString()
  );
  if (!requests) {
    throw new Error('No Service Requests Found');
  }

  // Now fetch the Patient, Specimen, and Observation resources associated with the order
  const result = await Promise.all(
    requests.map(async (request) => {
      let subject = undefined;

      // Fetch the associated Patient resource referenced in the `request.subject` property
      try {
        subject = await medplum.readReference(request.subject);
      } catch (err) {
        console.error(`Error fetching subject ${JSON.stringify(
          request.subject,
          null,
          2
        )} \
       in Service Request: ${JSON.stringify(request, null, 2)}`);
      }

      const tasks = await medplum.searchResources(
        'Task',
        `based-on=${getReferenceString(request)}&status:not=completed`
      );

      // Fetch the associated Specimen resource referenced in the `request.specimen` property
      if (!request.specimen?.[0]) {
        console.error(`WARN: Order ${request.id} missing Specimen reference`);
      }

      let specimens = request.specimen
        ? await Promise.all(
            request.specimen.map(async (specimen) => {
              return await medplum.readReference(specimen);
            })
          )
        : [];
      const specimenDayOne = getSpecimenByDay(specimens, '1');
      const specimenDayTwo = getSpecimenByDay(specimens, '2');

      // For new one box, passing extra row with regarding specimen and observations
      if (specimenDayTwo) {
        return [
          {
            serviceRequest: request,
            subject: subject,
            specimen: specimenDayOne,
            tasks: tasks,
          },
          {
            serviceRequest: request,
            subject: subject,
            specimen: specimenDayTwo,
            tasks: tasks,
          },
        ];
      }
      return [
        {
          serviceRequest: request,
          subject: subject,
          specimen: getSpecimenByDay(specimens, '1'),
          tasks: tasks,
        },
      ];
    })
  );
  return result.flat();
}

// This functuion filter the displayed task based on lab/cx
function filterRowDataByRole(orderData, role) {
  const filteredOrders = orderData.filter((order) => {
    for (const task of order.tasks) {
      const performerType = task.performerType[0].coding[0].code || '';
      if (performerType === role.toUpperCase()) {
        return true;
      }
    }
    return false;
  });
  return filteredOrders;
}

/*
This function organizes the raw FHIR data into rows to be displayed in the Dashboard table
*/
function getRowData(orderData, role) {
  const filteredOrder = filterRowDataByRole(orderData, role);
  const rows = filteredOrder.map((order) => {
    const { serviceRequest, tasks } = order;
    // Extract the barcode Id
    const barcode =
      getIdentifier(order.serviceRequest, 'kit/barcodeId') || '??';
    // Extract the Collected and Received dates from the specimen
    const collectedDate =
      order.specimen?.collection?.collectedDateTime &&
      new Date(order.specimen?.collection?.collectedDateTime);
    const receivedDate =
      order.specimen?.receivedTime && new Date(order.specimen?.receivedTime);

    // Extract the order's patient
    const patient = order.subject;

    // Extract the patient's state of residence. Only display it if it's NY
    let state = patient?.address?.[0].state || '';
    state = ['NY'].includes(state) ? state : '';

    // Extract sku
    const sku = order.serviceRequest.code?.coding?.filter(
      (e) => e.system === 'https://kit.com/sku'
    )[0]?.code;

    // Extract Processing Lab
    const lab =
      serviceRequest.performer &&
      serviceRequest.performer[0] &&
      serviceRequest.performer[0].display
        ? convertShortLabName(serviceRequest.performer[0].display)
        : 'In-house';

    // Package up all the data for this row into a flat object
    return {
      patient: {
        name:
          `${patient?.name?.[0]?.given?.[0]}  ${patient?.name?.[0]?.family} ` ||
          barcode,
        id: order.serviceRequest.id,
      },
      barcode,
      tubeId: order.specimen.accessionIdentifier?.value,
      birthdate: patient?.birthDate || '',
      carrier: order.serviceRequest.category?.[0].text,
      sku: sku,
      code: order.serviceRequest.code?.coding?.filter(
        (e) => e.system === 'https://kit.com/kitType'
      )[0]?.code,
      lastUpdated: order.serviceRequest.meta?.lastUpdated
        ? new Date(order.serviceRequest.meta?.lastUpdated)
        : null,
      status: order.serviceRequest.orderDetail?.[0].text,
      state: state || null,
      // Convert collected and received time to pure dates (no timestamp)
      collected: collectedDate ? removeTime(collectedDate) : null,
      received: receivedDate ? removeTime(receivedDate) : null,

      // Compute the number of days between when the sample was collected and when it was received
      stability:
        receivedDate &&
        collectedDate &&
        daysBetween(collectedDate, receivedDate),
      specimen: order.specimen,
      lab: lab,
      details: tasks
        .filter(
          (task) => task.performerType[0].coding[0].code === role.toUpperCase()
        )
        .map((task) => task.description),
    };
  });
  return rows;
}

// Return the specimen where its accessionId ends with desired day
// Return the first specimen if couldn't find such
function getSpecimenByDay(specimens, day) {
  for (const specimen of specimens) {
    if (specimen.accessionIdentifier?.value?.endsWith(day)) {
      return specimen;
    }
  }
  return specimens[parseInt(day) - 1];
}

/* Data manipulation utilities */

function renderPatient(params) {
  const cellValue = params.valueFormatted
    ? params.valueFormatted
    : params.value;
  if (!cellValue) {
    <span>Subject unknown</span>;
  }
  return (
    <Link className="no-underline" to={`/ServiceRequest/${cellValue.id}`}>
      {cellValue.name}
    </Link>
  );
}

function renderDetails(params) {
  const details = params.data.details;
  return (
    <div>
      {details.map((d) => (
        <li key={d}>{d}</li>
      ))}
    </div>
  );
}

function formatDateTime(params) {
  if (params.value instanceof Date) {
    return params.value.toLocaleDateString();
  }
  return params.value;
}

function getStateStyle(params) {
  if (['NY'].includes(params.value)) {
    return {
      backgroundColor: 'cornflowerblue',
      paddingLeft: '4px',
      paddingRight: '4px',
    };
  }
  return undefined;
}

function removeTime(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function daysBetween(before, after) {
  return Math.floor((after.getTime() - before.getTime()) / (1000 * 3600 * 24));
}

function convertShortLabName(labName) {
  if (labName === 'Atlantic Diagnostic Lab of Bensalem') {
    return 'ADL';
  }

  return 'In-house';
}
