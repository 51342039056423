import { AgGridReact } from 'ag-grid-react';
import {
  CellClassParams,
  CellEditRequestEvent,
  ColDef,
  CsvCell,
  HeaderValueGetterParams,
  ICellRendererParams,
  ITextFilterParams,
  ProcessCellForExportParams,
  ProcessRowGroupForExportParams,
  SelectionChangedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import PendingDashboard from '../components/pendingDashboard';
import { MedplumClient, getIdentifier, resolveId } from '@medplum/core';
import {
  Coding,
  Observation,
  Patient,
  Reference,
  ServiceRequest,
  Specimen,
} from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { Button, Loader } from '@mantine/core';
import React, { useEffect, useRef } from 'react';
import DatePicker from '../components/date-picker';
import {
  REQUIRED_ASSAYS,
  REVERSE_CATALOG,
  TestCategories,
  EXTRA_LABELS_NEEDED,
  T_HEALTH_REQUIRED_ASSAYS,
  SKU_TO_EXPIRY_DAYS,
  MACHINE_TITLES,
} from '../constants/LabCatalog';

// Search Parameters for getting relevant orders
const ORDER_SEARCH_PARAMS = new URLSearchParams({
  'order-detail': [
    'LAB_RECEIVED',
    'LAB_PROCESSED',
    'LAB_ASCENSIONED',
    'LAB_ACCESSIONED',
    'LAB_HOLD',
    'RESULTS_NEED_REVIEW',
  ].join(','),
  authored: 'gt2022-02-28',
  _count: '1000',
  'category:not': 'LOCAL_DONOR',
  _sort: '-_lastUpdated',
});

// Date format in YYYY-MM-DD
const DATE_OF_TODAY = new Date().toLocaleDateString('fr-CA');

// Static columns for the output display table
const TABLE_COLUMNS: ColDef[] = [
  {
    field: 'patient',
    headerName: 'Patient',
    cellRenderer: renderPatient,
    pinned: 'left',
    width: 150,
  },
  {
    field: 'barcode',
    headerName: 'Barcode',
    headerValueGetter: countSelectedValueGetter,
    pinned: 'left',
    width: 100,
    filter: 'agNumberColumnFilter',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
  },
  { field: 'tubeId', headerName: 'Tube ID', pinned: 'left', width: 60 },
  { field: 'birthdate', headerName: 'Date of Birth', hide: true },
  { field: 'carrier', headerName: 'Carrier', pinned: 'left', width: 50 },
  { field: 'sku', headerName: 'SKU', pinned: 'left' },
  {
    field: 'lastUpdated',
    headerName: 'Last Updated',
    valueFormatter: formatDateTime,
  },
  { field: 'status', headerName: 'Status', width: 120 },
  {
    field: 'state',
    headerName: 'State',
    width: 50,
    cellStyle: getStateStyle,
  },
  {
    field: 'awaited',
    headerName: 'Awaiting Days',
    valueFormatter: formatDateTime,
    editable: false,
    width: 100,
    cellStyle: getAwaitedStyle,
  },
  {
    field: 'ordered',
    headerName: 'Ordered',
    valueFormatter: formatDateTime,
    filter: 'agDateColumnFilter',
    editable: false,
  },
  {
    field: 'collected',
    headerName: 'Collected',
    valueFormatter: formatDateTime,
    filter: 'agDateColumnFilter',
    editable: true,
    cellEditor: DatePicker,
    width: 130,
  },
  {
    field: 'received',
    headerName: 'Received',
    valueFormatter: formatDateTime,
    filter: 'agDateColumnFilter',
    editable: true,
    cellEditor: DatePicker,
  },
  { field: 'stability', headerName: 'Stability', width: 50 },
  {
    field: 'lab',
    headerName: 'Lab',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset'],
      suppressAndOrCondition: true,
    } as ITextFilterParams,
    width: 50,
  },
];

/*
This is main component that displays the Pending Orders Dashboard
*/
export default function KitPendingOrdersDashboard(): JSX.Element {
  const medplum = useMedplum();

  // Refresh the page every 1 hr
  useEffect(() => {
    const timer = setInterval(
      window.location.reload.bind(window.location),
      3600000
    );
    return () => {
      clearInterval(timer);
    };
  }, []);

  // Get the master list of test categories from an external source
  const [testCategories, setTestCategories] = React.useState<string[]>([]);
  useEffect(() => {
    fetchTestCategories().then((categories) => setTestCategories(categories));
  }, []);

  // Dynamically generate columns
  const columnDefs: ColDef[] = [
    ...TABLE_COLUMNS,
    ...testCategories.map((e) => {
      return {
        field: e,
        headerName: MACHINE_TITLES[e],
        cellRenderer: renderObservationSummary,
      };
    }),
  ];
  const defaultColumnDefs: ColDef = DEFAULT_COL_DEFS;

  // Fetch row data every few seconds
  const [rows, setRows] = React.useState<any[] | undefined>();
  useEffect(() => {
    async function fetchAndSetRows() {
      fetchOrderData(medplum).then((orderData) =>
        setRows(getRowData(orderData))
      );
    }
    fetchOrderData(medplum).then((orderData) => setRows(getRowData(orderData)));
    const timer = setInterval(fetchAndSetRows, REFRESH_INTERVAL);
    return () => {
      clearInterval(timer);
    };
  }, [medplum]);

  /*
  At the top of the screen, we aggregate the counts of the rows by date.
  This state stores the grouped rows by date
  */
  const [rowsByDate, setRowsByDate] = React.useState<Record<string, number>>(
    {}
  );

  useEffect(
    () => rows && setRowsByDate(getRowCountByReceivedDate(rows)),
    [rows]
  );

  // Display a count of samples ready to be reviewed
  const [countReadyReviewInHouse, setCountReadyReviewInHouse] =
    React.useState<number>(0);
  const [countReadyReviewPartner, setCountReadyReviewPartner] =
    React.useState<number>(0);
  const [countDailyResultSent, setCountDailyResultSent] =
    React.useState<number>(0);

  useEffect(() => {
    // Return the count of daily results sent
    async function fetchCountDailyResulted() {
      const dailyResultedParams = new URLSearchParams({
        issued: `gt${DATE_OF_TODAY}T08:00:00.000Z`,
        _summary: 'count',
      });
      const reported = await medplum.search(
        'DiagnosticReport',
        dailyResultedParams
      );
      return reported.total;
    }

    if (rows) {
      const countReadyReviewRecord = getRowCountReadyReview(rows);
      setCountReadyReviewInHouse(
        countReadyReviewRecord.countReadyReviewInHouse || 0
      );
      setCountReadyReviewPartner(
        countReadyReviewRecord.countReadyReviewPartner || 0
      );
      fetchCountDailyResulted().then((res) =>
        res ? setCountDailyResultSent(res) : null
      );
    }
  }, [rows]);

  const gridRef = useRef<AgGridReact | null>(null);

  if (!testCategories) {
    return <Loader />;
  }

  return (
    <div style={{ height: '100%' }}>
      <div id="header" className="flex m-3 mb-0 justify-between">
        <div>
          <p>
            <span className="font-bold p-1 text-overdue-color">Red</span>=
            Specimen Exipired
          </p>
          <p>
            <span className="font-bold p-1 text-completed-color">Green</span>=
            All Assays Completed
          </p>
          <p>
            <span className="font-bold p-1 text-warning-color">Orange</span>=
            Last Day before Specimen Expired
          </p>
        </div>
        <div className="flex flex-col">
          <div className="pb-5">
            <Button onClick={() => handleExportCsv(gridRef.current, true)}>
              Export CSV Single
            </Button>
            <span>
              &nbsp;&nbsp;export single entry barcodes for selected rows
            </span>
          </div>
          <div>
            <Button onClick={() => handleExportCsv(gridRef.current, false)}>
              Export CSV Dynamic
            </Button>
            <span>
              &nbsp;&nbsp;export multiple entries barcodes for selected rows
            </span>
          </div>
        </div>
        <div className="flex">
          <div className="pr-6">
            <p className="m-0">
              Count of samples ready to review <br />
            </p>
            <p className="m-0">
              Total: {countReadyReviewInHouse + countReadyReviewPartner}
            </p>
            <p className="m-0">In-house: {countReadyReviewInHouse}</p>
            <p className="m-0">Partner Lab: {countReadyReviewPartner}</p>
            <p className="m-0 mt-3">
              Count of result sent today ({DATE_OF_TODAY})
            </p>
            <p className="m-0">Total: {countDailyResultSent}</p>
          </div>
          <div>
            {Object.entries(rowsByDate).map(([date, count]) => {
              return (
                <div
                  className="flex justify-between text-xs"
                  key={date.toString()}
                >
                  <span className="pr-5">{date}</span>
                  <span>{count}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <PendingDashboard
        ref={gridRef}
        rows={rows}
        columns={columnDefs}
        defaultColDef={defaultColumnDefs}
        onCellEditRequest={(event) => handleCellEditRequest(event, medplum)}
        rowSelection={'multiple'}
        suppressRowClickSelection={true}
        onSelectionChanged={onSelectionChanged}
      />
    </div>
  );
}

/*** Data Fetching  ***/

// Fetch all data related to each lab order from the server
async function fetchOrderData(medplum: MedplumClient): Promise<OrderData[]> {
  // First, we need to invalidate the caches to make sure we get fresh data
  medplum.invalidateSearches('ServiceRequest');

  // Fetch all the lab orders (i.e. ServiceRequests) to match the criteria in ORDER_SEARCH_PARAMS
  const requests: ServiceRequest[] = await medplum.searchResources(
    'ServiceRequest',
    ORDER_SEARCH_PARAMS.toString()
  );
  if (!requests) {
    throw new Error('No Service Requests Found');
  }

  // Now fetch the Patient, Specimen, and Observation resources associated with the order
  const result = await Promise.all(
    requests.map(async (request) => {
      let subject: Patient | undefined = undefined;

      // Fetch the associated Patient resource referenced in the `request.subject` property
      try {
        subject = await medplum.readReference(
          request.subject as Reference<Patient>
        );
      } catch (err) {
        console.error(
          `Error fetching subject ${JSON.stringify(request.subject, null, 2)} \
           in Service Request: ${JSON.stringify(request, null, 2)}`
        );
      }

      const processingLab =
        request.performer?.[0]?.display ===
        'Atlantic Diagnostic Lab of Bensalem'
          ? 'ADL'
          : 'in-house';

      // Fetch the associated Specimen resource referenced in the `request.specimen` property
      if (!request.specimen?.[0]) {
        console.error(`WARN: Order ${request.id} missing Specimen reference`);
      }

      /*
      Now fetch the associated observation resources.
      Observations have references to Subjects (not the other way around) using the `basedOn` property.
      To fetch the relevant observations, we must run a search for observations that point to the current order
      */
      const observations = await medplum.searchResources(
        'Observation',
        new URLSearchParams({
          'based-on': `ServiceRequest/${request.id}`,
          _count: '50',
        })
      );

      let specimens: Specimen[] = request.specimen
        ? await Promise.all(
            request.specimen.map(async (specimen) => {
              return await medplum.readReference<Specimen>(specimen);
            })
          )
        : [];
      const specimenDayOne = getSpecimenByDay(specimens, '1');
      const specimenDayTwo = getSpecimenByDay(specimens, '2');

      // For new one box, passing extra row with regarding specimen and observations
      if (specimenDayTwo) {
        var observationDayOne: Observation[] = [];
        var observationDayTwo: Observation[] = [];

        for (var observation of observations) {
          const specimenIdFromObs = resolveId(observation.specimen);
          if (!specimenIdFromObs) {
            observationDayOne.push(observation);
          } else {
            if (specimenIdFromObs === specimenDayTwo.id) {
              observationDayTwo.push(observation);
            } else {
              observationDayOne.push(observation);
            }
          }
        }
        return [
          {
            serviceRequest: request,
            subject: subject,
            specimen: specimenDayOne,
            observations: observationDayOne,
            processingLab: processingLab,
          } as OrderData,
          {
            serviceRequest: request,
            subject: subject,
            specimen: specimenDayTwo,
            observations: observationDayTwo,
            processingLab: processingLab,
          } as OrderData,
        ];
      }
      return [
        {
          serviceRequest: request,
          subject: subject,
          specimen: getSpecimenByDay(specimens, '1'),
          observations: observations,
          processingLab: processingLab,
        },
      ];
    })
  );
  return result.flat();
}

// Return the specimen where its accessionId ends with desired day
// Return the first specimen if couldn't find such
function getSpecimenByDay(specimens: Specimen[], day: string) {
  for (const specimen of specimens) {
    if (specimen.accessionIdentifier?.value?.endsWith(day)) {
      return specimen;
    }
  }
  return specimens[parseInt(day) - 1];
}

// Get the mapping of carrier -> required test categories.
// (Right now this is hardcoded, but eventually will be fetched from Medplum)
async function fetchTestCategories(): Promise<string[]> {
  return new Promise<string[]>((resolve, reject) => {
    resolve(TestCategories);
  });
}

/*
This function organizes the raw FHIR data into rows to be displayed in the Dashboard table
*/
function getRowData(orderData: OrderData[]) {
  const rows: any[] = orderData.map((order) => {
    // Extract the barcode Id
    const barcode =
      getIdentifier(order.serviceRequest, 'kit/barcodeId') || '??';
    // Extract the Collected and Received dates from the specimen
    const collectedDate =
      order.specimen?.collection?.collectedDateTime &&
      new Date(order.specimen?.collection?.collectedDateTime);
    const receivedDate =
      order.specimen?.receivedTime && new Date(order.specimen?.receivedTime);

    // Extract the original ordered date (creation)
    const orderedDate =
      order.serviceRequest.authoredOn &&
      new Date(order.serviceRequest.authoredOn);

    // Extract the carrier name from the order
    const carrier = order.serviceRequest.category?.[0]?.text?.toLowerCase();

    // Extract the order's patient
    const patient = order.subject;

    // Extract the patient's state of residence. Only display it if it's NY
    let state = patient?.address?.[0].state || '';
    state = ['NY'].includes(state) ? state : '';

    // Group the observations by their category and get the count and most recent date for each category
    const summarizedObservations = summarizeObservations(order.observations);

    // Check if all the required categories for a given carrier have at least 1 observation in them.
    // If so, the order is considered "complete" and we can highlight it in green
    let requirementsFulfilled = false;
    const sku = order.serviceRequest.code?.coding?.filter(
      (e: Coding) => e.system === 'https://kit.com/sku'
    )[0]?.code;
    if (carrier) {
      const day = order.specimen?.accessionIdentifier?.value?.endsWith('2')
        ? '2'
        : '1';
      const requiredAssays: string[] =
        getRequiredAssays(carrier, sku, day) || TestCategories;
      requirementsFulfilled = requiredAssays.reduce(
        (cur: boolean, category) => {
          return (
            cur &&
            summarizedObservations[category] &&
            summarizedObservations[category].count > 0
          );
        },
        true
      );
    }

    // Package up all the data for this row into a flat object
    return {
      patient: {
        name:
          `${patient?.name?.[0]?.given?.[0]}  ${patient?.name?.[0]?.family} ` ||
          barcode,
        id: order.serviceRequest.id,
      },
      barcode,
      tubeId: order.specimen.accessionIdentifier?.value,
      birthdate: patient?.birthDate || '',
      carrier: order.serviceRequest.category?.[0].text,
      sku: sku,
      code: order.serviceRequest.code?.coding?.filter(
        (e: Coding) => e.system === 'https://kit.com/kitType'
      )[0]?.code,
      lastUpdated: order.serviceRequest.meta?.lastUpdated
        ? new Date(order.serviceRequest.meta?.lastUpdated)
        : null,
      status: order.serviceRequest.orderDetail?.[0].text,
      state: state || null,
      // Convert collected and received time to pure dates (no timestamp)
      collected: collectedDate ? removeTime(collectedDate) : null,
      received: receivedDate ? removeTime(receivedDate) : null,
      ordered: orderedDate ? removeTime(orderedDate) : null,

      // Compute the number of days between when the sample was collected and when it was received
      stability:
        receivedDate &&
        collectedDate &&
        daysBetween(collectedDate, receivedDate),
      awaited: receivedDate && daysBetween(receivedDate, new Date()),
      specimen: order.specimen,
      requirementsFulfilled: requirementsFulfilled,
      lab: order.processingLab,

      // Add the summarized observations as the final elements of this object
      ...summarizedObservations,
    };
  });
  return rows;
}

/*** Data manipulation utilities  ***/

/*
Groups a list of observations by their category, and returns the count and most recent date for each category
*/
function summarizeObservations(observations: Observation[]) {
  const aggegatedObservations: Record<string, Observation[]> = {};
  observations.forEach((ob) => {
    const category = REVERSE_CATALOG[ob.code!.text!];
    if (!(category in aggegatedObservations)) {
      aggegatedObservations[category] = [];
    }
    aggegatedObservations[category].push(ob);
  });

  function latestUpdate(obs: Observation[]) {
    return new Date(
      Math.max(
        ...obs
          .map((e) => e.meta?.lastUpdated)
          .filter((e) => e !== undefined)
          .map((e) => new Date(e!).getTime())
      )
    );
  }

  const observationSummaries: Record<string, ObservationSummary> =
    Object.fromEntries(
      Object.entries(aggegatedObservations).map(([cat, obs]) => {
        return [cat, { count: obs.length, lastUpdated: latestUpdate(obs) }];
      })
    );

  return observationSummaries;
}

// Compute the count of orders grouped by date Received
function getRowCountByReceivedDate(rows: any[]): Record<string, number> {
  const dates = rows
    .map((e) => e.received)
    .sort((d1: Date, d2: Date) => {
      if (!d1) {
        return Number.POSITIVE_INFINITY;
      }
      if (!d2) {
        return Number.NEGATIVE_INFINITY;
      }
      return -daysBetween(d2, d1);
    });

  const results = Object.fromEntries(
    dates.map((date) => [getFormattedDate(date), 0])
  );

  rows.forEach((row) => {
    results[getFormattedDate(row.received)] += 1;
  });

  function getFormattedDate(date: Date | undefined | null): any {
    return date ? date.toLocaleDateString() : 'Blank';
  }

  return results;
}

function getRowCountReadyReview(rows: any[]): Record<string, number> {
  let rowCount = { countReadyReviewInHouse: 0, countReadyReviewPartner: 0 };
  rows.forEach((row) => {
    if (row.requirementsFulfilled) {
      row.lab === ''
        ? (rowCount.countReadyReviewInHouse += 1)
        : (rowCount.countReadyReviewPartner += 1);
    }
  });
  return rowCount;
}

function formatDateTime(params: ValueFormatterParams) {
  if (params.value instanceof Date) {
    return params.value.toLocaleDateString();
  }
  return params.value;
}

function daysBetween(before: Date, after: Date): number {
  return Math.floor((after.getTime() - before.getTime()) / (1000 * 3600 * 24));
}

function getRequiredAssays(carrier: string, sku = '', day = '1'): string[] {
  if (sku !== '' && sku in REQUIRED_ASSAYS) {
    sku = sku.toUpperCase();
    return REQUIRED_ASSAYS[sku] || TestCategories;
  }
  if (sku !== '' && sku === 'BDO-2/2A-RO-TT') {
    return T_HEALTH_REQUIRED_ASSAYS[day];
  }
  carrier = carrier.toUpperCase();
  return REQUIRED_ASSAYS[carrier] || TestCategories;
}

/*** Display Formatting Functions ***/

/*
This is the main styling function for each row.
`getCellClass` gets called once per row, and applies business logic to determing
the function returns an optional set of CSS class names used to style this row
*/
function getCellClass(params: CellClassParams): string | string[] | null {
  const fieldName = params.colDef.field;
  const carrier: string = params.data.carrier
    ? params.data.carrier.toLowerCase()
    : '';
  const day = params.data.specimen?.accessionIdentifier?.value?.endsWith('2')
    ? '2'
    : '1';
  const requiredAssays = params.data.sku
    ? getRequiredAssays(carrier, params.data.sku, day)
    : getRequiredAssays(carrier);

  // If the Test category is not applicable, grey out the cells
  if (
    fieldName &&
    TestCategories.includes(fieldName) &&
    carrier &&
    !requiredAssays.includes(fieldName)
  ) {
    return 'bg-assay-disabled';
  }

  // If all assays have been completed, highlight in green, ignoring stability
  if (params.data.requirementsFulfilled) {
    return 'bg-completed-color';
  }

  // Different expire conditions for different customers
  if (params.data.stability) {
    const threshold =
      params.data.sku in SKU_TO_EXPIRY_DAYS
        ? SKU_TO_EXPIRY_DAYS[params.data.sku]
        : 7;
    if (params.data.stability >= threshold) {
      return 'bg-overdue-color';
    }
    if (params.data.stability === threshold - 1) {
      // #row - last - day - valid;
      return 'bg-warning-color';
    }
  }

  // Highlight Lab Ascensioned
  if (
    params.data.status.toUpperCase() === 'LAB_ASCENSIONED' ||
    params.data.status.toUpperCase() === 'LAB_ACCESSIONED'
  ) {
    // #row - ascensioned;
    return 'bg-ascensioned-color';
  }

  return null;
}

function getStateStyle(params: CellClassParams) {
  if (['NY'].includes(params.value)) {
    return {
      backgroundColor: 'cornflowerblue',
      paddingLeft: '4px',
      paddingRight: '4px',
    };
  }
  return undefined;
}

function getAwaitedStyle(params: CellClassParams) {
  if (params.value >= 5) {
    return {
      backgroundColor: 'crimson',
      paddingLeft: '4px',
      paddingRight: '4px',
    };
  }
  return undefined;
}

function renderPatient(params: ICellRendererParams): JSX.Element {
  const cellValue = params.valueFormatted
    ? params.valueFormatted
    : params.value;
  if (!cellValue) {
    <span>Subject unknown</span>;
  }
  return (
    <a href={`https://app.medplum.com/ServiceRequest/${cellValue.id}`}>
      {cellValue.name}
    </a>
  );
}

function renderObservationSummary(params: ICellRendererParams): JSX.Element {
  const cellValue: ObservationSummary | undefined = params.valueFormatted
    ? params.valueFormatted
    : params.value;
  return cellValue ? (
    <span>{`${cellValue.lastUpdated.toLocaleDateString()}`}</span>
  ) : (
    <span></span>
  );
}

function removeTime(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

/** Event Handlers ***/
async function handleCellEditRequest(
  event: CellEditRequestEvent,
  medplum: MedplumClient
) {
  const field = event.colDef.field;
  if (field === 'collected' || field === 'received') {
    const newValue = event.newValue as Date;
    const specimen: any = event.data.specimen;
    const collection = specimen.collection || {};
    if (field === 'collected') {
      collection.collectedDateTime = newValue.toISOString();
      specimen.collection = collection;
    } else if (field === 'received') {
      specimen.receivedTime = newValue.toISOString();
    }

    try {
      await medplum.updateResource(specimen as Specimen);
      event.node.setDataValue(event.column, newValue);
    } catch {
      throw new Error(`Unable to update specimen ${specimen.id}
            with collection date ${newValue}`);
    }
  }
}

function handleExportCsv(grid: AgGridReact | null, isSingleEntry: boolean) {
  if (!grid) {
    return;
  }
  grid.api.exportDataAsCsv({
    columnKeys: ['patient', 'barcode', 'sku', 'birthdate'],
    fileName: isSingleEntry ? 'barcodes_single.csv' : 'barcodes_dynamic.csv',

    // Only export rows that have been checked
    onlySelected: true,

    // For cells, we need to apply custom logic to extract the value
    processCellCallback: (params: ProcessCellForExportParams) => {
      if (params.column.getColDef().field === 'patient') {
        return (params.value as any).name;
      }
      // Printout accessionId instead of barcode if exists for only T-health
      if (
        params.column.getColDef().field === 'barcode' &&
        params.node?.data?.sku === 'BDO-2/2A-RO-TT'
      ) {
        const accessionId =
          params.node?.data?.specimen?.accessionIdentifier?.value || '';
        if (accessionId !== '') {
          return accessionId;
        }
      }
      return params.value;
    },

    // Export extra rows for certain skus
    getCustomContentBelowRow: (params: ProcessRowGroupForExportParams) => {
      const data = params.node.data;
      //const accessionId = params.node.data.specimen?.accessionIdentifier?.value || '';
      // If "Export CSV Single" is clicked, don't append any extra rows
      if (data.sku === undefined || isSingleEntry) {
        return '';
      }
      var result = [] as CsvCell[][];
      var extraLabel = 0;
      if (data.sku.startsWith('FME')) {
        extraLabel = 4;
      } else if (data.sku in EXTRA_LABELS_NEEDED) {
        extraLabel = EXTRA_LABELS_NEEDED[data.sku];
      } else {
        return '';
      }
      for (let i = 0; i < extraLabel; i++) {
        const id =
          data.sku === 'BDO-2/2A-RO-TT'
            ? data.specimen?.accessionIdentifier?.value
            : data.barcode;
        result.push([
          { data: { value: data.patient?.name } },
          { data: { value: id } },
          { data: { value: data.sku } },
          { data: { value: data.birthdate } },
        ]);
      }
      return result;
    },
  });
}

// Display the row count which has been selected
function countSelectedValueGetter(params: HeaderValueGetterParams) {
  return `Barcode (${params.api.getSelectedRows().length})`;
}

function onSelectionChanged(event: SelectionChangedEvent) {
  event.api.refreshHeader();
}

/* Type Definitions */

// Type defining all data about an order
type OrderData = {
  serviceRequest: ServiceRequest;
  subject?: Patient;
  specimen: Specimen;
  observations: Observation[];
  processingLab: String;
};

// Type defining aggregated information about an
// order's results (i.e. Observations)
type ObservationSummary = {
  count: number;
  lastUpdated: Date;
};

/* Constants */
// Behavior Constants
const REFRESH_INTERVAL = 120000;

// Default values for column styling
const DEFAULT_COL_DEFS: ColDef = {
  resizable: true,
  sortable: true,
  width: 100,
  wrapText: true,
  enableCellChangeFlash: true,
  cellStyle: {
    fontSize: '11px',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  cellClass: getCellClass,
};
