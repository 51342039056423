import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Loading from "../components/loading";
import StandardButton from "../components/standardButton";

function OverflowConfiguration() {
  const queryClient = useQueryClient();
  const [sku, setSku] = useState("BDO-5A-RO-BY");
  const [threshold, setThreshold] = useState(150);

  const BACKEND = process.env.REACT_APP_SERVER_URL;
  const { getAccessTokenSilently, user } = useAuth0();
  const userRoles = user["kit/roles"];

  const { data: backendState } = useQuery("config", async () => {
    const token = await getAccessTokenSilently();
    const res = await axios.get(`${BACKEND}/overflow_configuration`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  });

  // table of sku, count, and threshold
  let thresholdDisplay = null;
  if (backendState) {
    thresholdDisplay = backendState.map((row) => {
      return (
        <tr>
          <td className="text-left border-b px-8 py-8">{row?.sku}</td>
          <td className="text-left border-b px-8 py-8">{row?.count}</td>
          <td className="text-left border-b px-8 py-8">{row?.threshold}</td>
        </tr>
      );
    });
  }
  // add header
  // use styling from other table
  thresholdDisplay = (
      <table className="table-fixed w-full bg-kit-light-2 ">
      <tr>
        <th className="text-left border-b px-8 py-8">SKU</th>
        <th className="text-left border-b px-8 py-8">Count</th>
        <th className="text-left border-b px-8 py-8">Threshold</th>
      </tr>
      {thresholdDisplay}
    </table>
  );

  const configMutation = useMutation(
    async ({ sku, threshold }) => {
      const body = {
        sku: sku,
        threshold: threshold,
      };

      const token = await getAccessTokenSilently();

      const res = await axios.post(`${BACKEND}/overflow_configuration`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("config");
      },
    }
  );

  function onSubmit(e) {
    e.preventDefault();
    configMutation.mutate({ sku, threshold });
  }

  return (
    <div className="h-screen overflow-hidden">
      <div className="h-full flex flex-col justify-around">
        <div>
          <h2>{thresholdDisplay}</h2>
        </div>
        <form className="w-6/12 self-center bg-kit-light-2 rounded-xl p-8">
          <div>
            <h2 className="text-xl">Set Overflow Amount</h2>
          </div>
          <div className="flex h-full flex-col justify-around">
            <div>
              <label htmlFor="sku">
                <h3>SKU</h3>
                <select
                  onChange={(e) => setSku(e.target.value)}
                  id="sku"
                  className="w-full"
                >
                  <option value="BDO-5A-RO-BY">BDO-5A-RO-BY</option>
                </select>
              </label>
            </div>
            <div>
              <label htmlFor="threshold">
                <h3>Threshold</h3>
                <input
                  type="text"
                  onChange={(e) => setThreshold(e.target.value)}
                  id="threshold"
                  className="w-full"
                ></input>
              </label>
            </div>
            <div>
              <div>
                <StandardButton onClick={(e) => onSubmit(e)}>
                  Confirm configuration
                </StandardButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OverflowConfiguration;
