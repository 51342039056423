import axios from 'axios';
import { Button, Group, Input, Select } from '@mantine/core';
import {
  Document,
  Container,
  useMedplum,
  DateTimeInput,
  QuestionnaireForm,
} from '@medplum/react';
import { useEffect, useState } from 'react';

const ObxReasonCodes = [
  'missingObservationInterpretation',
  'missingObservationValue',
  'missingObservation',
  'missingObservationInterpretation',
];
const OWB_ID_STAGING = '6457845d-2cb4-43aa-9a89-a1a434f9d358';
const OWB_ID_PROD = '825795ce-c240-4f67-afa3-f767a0eb17c9';

export function ActionItems({ tasks, role }) {
  const medplum = useMedplum();

  const [dateTime, setDateTime] = useState();
  const [gender, setGender] = useState();
  const [testResults, setTestResults] = useState();

  useEffect(() => {
    async function buildTestResults() {
      const testResultQuestionnaire = await medplum.searchOne(
        'Questionnaire',
        'identifier:contains=test-results'
      );
      const questionnaireComponent = (
        <Document>
          <QuestionnaireForm
            questionnaire={testResultQuestionnaire}
            subject={tasks?.[0].for || null}
            submitButtonText='Apply'
            onSubmit={(formData) => {
              repairObservations(formData);
            }}
          />
        </Document>
      );
      setTestResults(questionnaireComponent);
    }
    buildTestResults();
  }, [medplum, tasks]);

  function renderActionItems() {
    const performerTasks = tasks.filter(
      (task) => task.performerType[0].coding[0].code === role.toUpperCase()
    );
    const hasObxReason =
      performerTasks.filter((task) =>
        ObxReasonCodes.includes(task.reasonCode.coding[0].code)
      ).length > 0;
    const allInputJsx = performerTasks.map((task) => {
      return (
        <div key={task.id}>
          <Document>{renderActionItem(task)}</Document>
        </div>
      );
    });
    return (
      <div>
        {allInputJsx}
        {hasObxReason ? (
          testResults
        ) : (
          <Group position='right'>
            <Button onClick={() => repairActionItem()}>Apply</Button>
          </Group>
        )}
      </div>
    );
  }

  function renderActionItem(task) {
    if (task.status === 'completed') {
      return <div>This field has been repaired.</div>;
    }
    const code = task.reasonCode.coding[0].code;
    var inputJsx = <div>Please repair in Medplum.</div>;
    if (code === 'missingReceivedDate' || code === 'missingCollectionDate') {
      inputJsx = <DateTimeInput onChange={setDateTime} />;
    } else if (code === 'missingGender') {
      inputJsx = (
        <Select
          value={gender}
          onChange={setGender}
          placeholder='Gender'
          data={[
            { value: 'male', label: 'Male' },
            { value: 'female', label: 'Female' },
          ]}
        />
      );
    } else if (ObxReasonCodes.includes(code)) {
      inputJsx = <div>Please fill in missing field using the form below.</div>;
    }

    return (
      <div>
        <Input.Wrapper label={task.description || ''}>{inputJsx}</Input.Wrapper>
      </div>
    );
  }

  async function repairActionItem() {
    for (const task of tasks) {
      if (!(task.focus && task.focus.reference)) {
        alert('Error: Missing field task.focus or task.focus.reference');
        continue;
      }
      const reasonCode = task.reasonCode.coding[0].code;
      const resource = await medplum.readReference(task.focus);
      if (reasonCode === 'missingCollectionDate' && dateTime) {
        resource.collection = { collectedDateTime: dateTime };
      } else if (reasonCode === 'missingReceivedDate' && dateTime) {
        resource.receivedTime = dateTime;
      } else if (reasonCode === 'missingGender' && gender) {
        resource.gender = gender;
      } else {
        continue;
      }
      medplum
        .updateResource(resource)
        .then(async (res) => {
          task.status = 'completed';
          await medplum.updateResource(task);
          updateStatusIfAllTasksCompleted();
          alert('SUCCESS: Fixed: ' + task.description);
        })
        .catch((error) =>
          alert('Error: Medplum resouce update failed!' + error)
        );
    }
  }

  async function repairObservations(formData) {
    // First Repair other missing resources - there's only single apply button
    repairActionItem();

    // POST QuestionnaireResponse as data to Order Workflow Bot
    const token = medplum.getAccessToken();
    const OWB_ID =
      process.env.REACT_APP_KIT_ENV === 'Prod' ? OWB_ID_PROD : OWB_ID_STAGING;
    try {
      const res = await axios.post(
        `https://api.medplum.com/fhir/R4/Bot/${OWB_ID}/$execute`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status >= 400) {
        alert(
          'Failed POSTing QuestionnaireResponse to OWB! Please reach out ENG team: ' +
            res.data
        );
        return;
      }

      // mark all obs-related tasks as completed if res == 200
      if (res.status === 200) {
        const taskRes = await Promise.all([
          completeAllObsTasks(),
          updateStatusIfAllTasksCompleted(),
        ]);
        if (taskRes) {
          alert('SUCCESS: Fixed: Observation');
        }
      }
    } catch (err) {
      alert(
        'Failed POSTing QuestionnaireResponse to OWB! Please reach out ENG team: ' +
          err.response.data?.errorMessage
      );
    }
  }

  // Ready to review if all tasks has been repaired
  async function updateStatusIfAllTasksCompleted() {
    if (tasks) {
      for (const task of tasks) {
        if (task.status !== 'completed') {
          return;
        }
      }
      const sr = await medplum.readReference(tasks[0].for);
      if (sr.orderDetail?.[0].text) {
        sr.orderDetail[0].text = 'RESULTS_NEED_REVIEW';
        await medplum.updateResource(sr);
      }
    }
  }

  async function completeAllObsTasks() {
    if (tasks) {
      for (const task of tasks) {
        const reasonCode = task.reasonCode?.coding?.[0].code || '';
        if (ObxReasonCodes.includes(reasonCode)) {
          task.status = 'completed';
          await medplum.updateResource(task);
        }
      }
    }
  }

  return (
    <>
      <Container>{tasks && renderActionItems()}</Container>
    </>
  );
}
