import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import Loading from '../components/loading';
import PatientInput from '../components/patientInput';
import StandardButton from '../components/standardButton';

function ShippingLabels() {
  const [barcodeId, setBarcodeId] = useState('');
  const [boxType, setBoxType] = useState('BDO');
  const [shippingType, setShippingType] = useState('UPS');
  const [shippingDay, setShippingDay] = useState('Ground');

  const [returnShippingType, setReturnShippingType] = useState('UPS');
  const [returnShippingDay, setReturnShippingDay] = useState('NextDayAir');

  const BACKEND = process.env.REACT_APP_SERVER_URL;
  const { user, getAccessTokenSilently } = useAuth0();
  const roles = user['kit/roles'];

  function handleChange(e) {
    console.log(e.target.value);
    if (e.target.value.length >= 6) {
      e.target.value = e.target.value.substring(0, 6);
    }
    setBarcodeId(e.target.value.replace('PBK', ''));
  }

  const labelMutation = useMutation(async ({ e, barcodeId, warehouseRole }) => {
    e.preventDefault();
    const body = {
      barcode_id: barcodeId,
      boxType: boxType,
      shippingType: shippingType,
      shippingDay: shippingDay,
      returnShippingType: returnShippingType,
      returnShippingDay: returnShippingDay,
      warehouse: warehouseRole,
    };

    const token = await getAccessTokenSilently();

    const res = await axios.post(`${BACKEND}/shipping_label`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  });

  const handleBoxTypeChange = (e) => {
    setBoxType(e.target.value);
  };

  const handleShippingTypeChange = (e) => {
    setShippingType(e.target.value);
  };

  const handleShippingDayChange = (e) => {
    setShippingDay(e.target.value);
  };

  const handleReturnShippingTypeChange = (e) => {
    setReturnShippingType(e.target.value);
  };
  const handleReturnShippingDayChange = (e) => {
    setReturnShippingDay(e.target.value);
  };

  const filteredRoles = roles.filter((role) => {
    return 'warehouse-sfo' === role || 'warehouse-phx' === role;
  });

  if (filteredRoles.length !== 1) {
    return (
      <div>
        <div>You should have exactly one warehouse role</div>
        <div>{JSON.stringify(roles)}</div>
      </div>
    );
  }

  let [warehouseRole] = filteredRoles;
  if (!warehouseRole) {
    warehouseRole = 'none';
  }

  return (
    <div className="h-screen overflow-hidden">
      <div className="h-full flex flex-col justify-around">
        <div className="w-4/12 self-center">
          <PatientInput handleChange={handleChange} val={barcodeId} />
        </div>
        <form className="w-6/12 self-center bg-kit-light-2 rounded-xl p-8">
          <div>
            <h2 className="text-xl">Create shipping labels</h2>
          </div>
          <div className="flex h-full flex-col justify-around">
            <div>
              <label htmlFor="boxType">
                <h3>Box type</h3>
                <select
                  onChange={handleBoxTypeChange}
                  id="boxType"
                  className="w-full"
                >
                  <option value="BDO">BDO</option>
                  <option value="FME">FME</option>
                  <option value="BDBP">BDBP</option>
                  <option value="SAL">SAL</option>
                </select>
              </label>
            </div>
            <div>
              <label htmlFor="shippingType">
                <h3>Shipping Company</h3>
                <select
                  onChange={handleShippingTypeChange}
                  id="shippingType"
                  className="w-full"
                >
                  <option value="UPS">UPS</option>
                  <option value="USPS">USPS</option>
                </select>
              </label>
            </div>
            <div>
              <label htmlFor="shippingDay">
                <h3>Shipping Day</h3>
                <select
                  onChange={handleShippingDayChange}
                  id="shippingDay"
                  className="w-full"
                >
                  <option value="Ground">Ground</option>
                  <option value="2ndDayAir">2</option>
                  <option value="NextDayAir">Overnight</option>
                </select>
              </label>
            </div>
            <div>
              <label htmlFor="returnShippingType">
                <h3>Return Shipping Company</h3>
                <select
                  onChange={handleReturnShippingTypeChange}
                  id="returnShippingType"
                  className="w-full"
                  disabled
                >
                  <option value="UPS">UPS</option>
                </select>
              </label>
            </div>
            <div>
              <label htmlFor="returnShippingDay">
                <h3>Return Shipping Day</h3>
                <select
                  onChange={handleReturnShippingDayChange}
                  id="returnShippingDay"
                  className="w-full"
                  disabled
                >
                  <option value="NextDayAir">Overnight</option>
                </select>
              </label>
            </div>
            <div>
              <div>
                <StandardButton
                  onClick={(e) =>
                    labelMutation.mutate({ e, barcodeId, warehouseRole })
                  }
                >
                  make shipping labels
                </StandardButton>
                <div>{labelMutation.isLoading ? <Loading /> : ''}</div>
                <div>
                  {labelMutation.isError ? (
                    <div>
                      <div>Error</div>
                      <div>{labelMutation.error?.response?.data?.code}</div>
                      <div>{labelMutation.error?.response?.data?.name}</div>
                      <div>
                        {labelMutation.error?.response?.data?.description}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  {labelMutation.data ? (
                    <>
                      <div>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={labelMutation.data.label}
                        >
                          label
                        </a>
                      </div>
                      <div>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={labelMutation.data.returnLabel}
                        >
                          return label
                        </a>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ShippingLabels;
