// import QRCode from "react-qr-code";
import QRCode from "qrcode.react";

function LabeledQRCode(props) {
  return (
    <div className="bg-kit-light-2 px-4 py-4 rounded-xl">
      <QRCode size={props.size} value={props.value} />
      <div className="text-center">{props.value}</div>
    </div>
  );
}

export default LabeledQRCode;
