import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useState } from "react";
import { useMutation } from "react-query";
import Loading from "../components/loading";
import StandardButton from "../components/standardButton";

function OrageneBarcode() {
  const [boxId, setBoxId] = useState("");
  const [orageneBarcode, setOrageneBarcode] = useState("");

  const BACKEND = process.env.REACT_APP_SERVER_URL;
  const { getAccessTokenSilently } = useAuth0();

  const labelMutation = useMutation(async ({ e }) => {
    e.preventDefault();
    const body = {
      boxId: boxId,
      orageneBarcode: orageneBarcode,
    };

    const token = await getAccessTokenSilently();

    const res = await axios.post(`${BACKEND}/oragene_barcode`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  });

  const handleInputChange = (e) => {
    setBoxId(e.target.value.replace("PBK", ""));
    if (e.target.value.length >= 6) {
      document.getElementById("orageneBarcode").focus();
    }
  };

  return (
    <div className="h-screen overflow-hidden">
      <div className="h-full flex flex-col justify-around">
        <form className="w-6/12 self-center bg-kit-light-2 rounded-xl p-8"
          onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}>
          <div>
            <h2 className="text-xl">Assign a Oragene Barcode</h2>
          </div>
          <div className="flex h-full flex-col justify-around">
            <div>
              <label htmlFor="boxId">
                <h3>Box ID</h3>
                <input
                  type="text"
                  onChange={handleInputChange}
                  value={boxId}
                  id="boxId"
                  className="w-full"
                  autoFocus
                ></input>
              </label>
            </div>
            <div>
              <label htmlFor="orageneBarcode">
                <h3>Oragene Barcode</h3>
                <input
                  type="orageneBarcode"
                  onChange={e => setOrageneBarcode(e.target.value)}
                  id="orageneBarcode"
                  className="w-full"
                ></input>
              </label>
            </div>
            <div>
              <div>
                <StandardButton onClick={(e) => labelMutation.mutate({ e })}>
                  Assign Oragene Barcode
                </StandardButton>
                <div>{labelMutation.isLoading ? <Loading /> : ""}</div>
                <div>
                  {labelMutation.isError ? (
                    <div>
                      <div>Error</div>
                      <div>{labelMutation.error?.response?.data?.code}</div>
                      <div>{labelMutation.error?.response?.data?.name}</div>
                      <div>
                        {labelMutation.error?.response?.data?.description}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {labelMutation.data ? (
                    <>
                      <div>Success!</div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OrageneBarcode;
