import { Logo, SignInForm, useMedplumProfile } from '@medplum/react';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const MEDPLUM_PROJECT_ID = process.env.REACT_APP_MEDPLUM_PROJECT_ID;
const MEDPLUM_CLIENT_ID = process.env.REACT_APP_MEDPLUM_CLIENT_ID;
const GOOGLE_CLIENT_ID = '349680304187-9dd4oi28nqkp38crcld07k4jsru89pbl.apps.googleusercontent.com';

function MedplumSignIn({ component: Component, ...rest }) {
  const profile = useMedplumProfile();
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  if (!profile) {
    return (
      <div>
        <SignInForm
          projectId={MEDPLUM_PROJECT_ID}
          googleClientId={GOOGLE_CLIENT_ID}
          clientId={MEDPLUM_CLIENT_ID}
          login={searchParams.get('login') || undefined}
          redirectUri={window.location.href}>
          <Logo size={32} />
          <h1>Sign in to Medplum</h1>
        </SignInForm>
      </div>
    );
  }

  return <Component {...rest} />;
}

export default MedplumSignIn;
