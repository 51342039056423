export const TestCategories = [
  'Immunoassays',
  'Chemistries',
  'A1C',
  'Hematology',
];

export const CATALOG = {
  Chemistries: [
    'CHOL',
    'GGT',
    'HDL',
    'LDL',
    'LDL-CALCULATED',
    'TRIG',
    'GLUCOSE',
    'BUN',
    'CREAT',
    'TP',
    'ALB',
    'ALP',
    'ALT',
    'AST',
    'HEM', // Hemolyzed (bloody)
    'LIP', // Lipemic (milky looking)
    'ICT', // Icteric (more yellow)
  ],
  A1C: ['HBA1C'],
  Immunoassays: [
    'ESTRADIOL',
    'INSULIN',
    'PSA_TOTAL',
    'PSA_FREE',
    'T3',
    'T4',
    'TESTOSTERONE',
    'TESTOSTERONE_UNITS',
    'TSH',
    'VITAMIN_D',
  ],
  'Self Collected': ['BP_SYSTOLIC', 'BP_DIASTOLIC', 'BP_PULSE', 'WEIGHT'],

  'NT-ProBNP': ['NT-PROBNP'],
  Hematology: ['HGB'],
};

export const REVERSE_CATALOG: Record<string, string> = {};
(Object.entries(CATALOG) as [string, string[]][]).forEach(
  ([category, codes]) => {
    codes.forEach((code) => (REVERSE_CATALOG[code] = category));
  }
);

export const REQUIRED_ASSAYS: Record<string, string[]> = {
  FABRIC: ['Chemistries', 'A1C'],
  PRD: ['Chemistries', 'A1C'],
  RO: ['Immunoassays'],
  WILDH: ['Immunoassays'],
  BDO_V1: ['Immunoassays'],
  SZN: ['A1C'],
  'BDO-5-RO-BY': ['Immunoassays', 'Chemistries', 'A1C'],
  'BDO-2-RO-TT/1': ['Immunoassays'],
  'FME-1-FAB-FL': ['Chemistries', 'A1C'],
  'FME-1-PRD-FL': ['Chemistries', 'A1C'],
  'BDO-7-SZN-BD': ['A1C'],
  'BDO-104-VRTA-GN': ['Chemistries', 'A1C'],
  'BDO-2-KIT-BD': ['Chemistries', 'A1C'],
  'BDO-106-RO-ED': ['Immunoassays', 'Chemistries', 'A1C'],
  'BDO-2B-RO-TT': ['Immunoassays', 'A1C', 'Hematology'],
  'BDBP-102-PMR-BD': ['Immunoassays', 'Chemistries', 'A1C'],
  'BDO-5A-RO-BY': ['Immunoassays', 'Chemistries', 'A1C'],
  'BDO-MONITOR-RO-TT': ['Immunoassays', 'Hematology', 'A1C'],
};

export const T_HEALTH_REQUIRED_ASSAYS: Record<string, string[]> = {
  '1': ['Immunoassays', 'Hematology'],
  '2': ['Immunoassays', 'A1C'],
};

export const EXTRA_LABELS_NEEDED: Record<string, number> = {
  'BDO-2-RO-TT/1': 2,
  'BDO-7-SZN-BD': 2,
  'BDO-2-KIT-BD': 4,
  'BDO-5-RO-BY': 4,
  'BDO-104-VRTA-GN': 4,
  'BDO-2/2A-RO-TT': 4,
  'BDO-106-RO-ED': 4,
  'BDO-2B-RO-TT': 4,
  'BDO-5A-RO-BY': 4,
};

export const SKU_TO_EXPIRY_DAYS: Record<string, number> = {
  'BDO-2-RO-TT/1': 6,
  'BDO-104-VRTA-GN': 4,
  'BDO-7-SZN-BD': 6,
  'FME-1-PRD-FL': 4,
  'BDO-5-RO-BY': 4,
  'BDO-2-KIT-BD': 4,
  'FME-1-FAB-FL': 4,
  'FME-1-KIT-FL': 4,
  'BDBP-102-PMR-BD': 4,
  'BDO-106-RO-ED': 4,
  'BDO-2/2A-RO-TT': 6,
  'BDO-2B-RO-TT': 4,
  'BDO-5A-RO-BY': 4,
};

export const MACHINE_TITLES: Record<string, string> = {
  Immunoassays: 'Roche (Immuno)',
  Chemistries: 'Beckman',
  A1C: 'Biorad',
  Hematology: 'Sysmex',
};
