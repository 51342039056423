import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import LabelTable from "../components/labelTable";
import BarcodeInput from "../components/barcodeInput";
import LabeledQRCode from "../components/labeledQRCode";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import StandardButton from "../components/standardButton";

const BACKEND = process.env.REACT_APP_SERVER_URL;

const LABEL_TYPES = {
  BOX: {
    value: "BOX",
    subtypes: ["t_health", "life_insurance", "life_insurance_comp"],
  },
  TUBE: {
    value: "TUBE",
    subtypes: [],
  },
  SALIVA_BAG: {
    value: "SALIVA_BAG",
    subtypes: [],
  },
  BLOOD_BAG: {
    value: "BLOOD_BAG",
    subtypes: [],
  },
  SCALE: {
    value: "SCALE",
    subtypes: [],
  },
  CENTRIFUGE: {
    value: "CENTRIFUGE",
    subtypes: [],
  },
  BLOOD_PRESSURE: {
    value: "BLOOD_PRESSURE",
    subtypes: [],
  },
};

function LabelItem(props) {
  return (
    <StandardButton
      onClick={(e) => props.handleClick(props.labelType)}
      size="labelItem"
    >
      {props.labelType}
    </StandardButton>
  );
}

function makeCSV(labels) {
  // map the labels to a csv of their id's
  const type = labels[0].type;
  function make(ids) {
    // join the csv with a newline
    const csv = ids.join("\n");

    // create fake element and click it to download the csv
    const element = document.createElement("a");
    element.display = "none";
    document.body.appendChild(element);
    const blob = new Blob([csv], { type: "text/csv" });
    const date = dayjs().format("YYYY-MM-DDTHH-mm-ss");
    const filename = `${date}.${type}.csv`;
    if (window.navigator && window.navigator.msSaveorOpenBlob) {
      window.navigator.msSaveorOpenBlob(blob, filename);
    } else {
      element.href = URL.createObjectURL(blob);
      element.download = filename;
      element.click();
    }
  }
  const ids = labels.map((label) => {
    return label.id;
  });

  make(ids);

  // Tubes require 2 forms of ID for legal reasons
  if (type === "TUBE") {
    const alt_ids = labels.map((label) => {
      return label.alt_id;
    });

    make(alt_ids);
  }
}

/**
 * simple list of labels. on click write the mapped number of labels to the database, and create csv of that size back to client for download
 * user uses the csv to upload to avery software and print to a template manually
 */
function LabelConfirmed(props) {
  return (
    <div className="h-screen flex flex-col justify-around">
      <div>
        <h1 className="text-center text-2xl">
          Labels were successfully assigned.
        </h1>
      </div>
      <div className="flex justify-around">
        <StandardButton size="big" onClick={props.onOk}>
          OK
        </StandardButton>
      </div>
    </div>
  );
}

function LabelCanceled(props) {
  return (
    <div className="h-screen flex flex-col justify-around">
      <div>
        <h1 className="text-center text-2xl">Labels were not assigned.</h1>
      </div>
      <div className="flex justify-around">
        <StandardButton size="big" onClick={props.onOk}>
          OK
        </StandardButton>
      </div>
    </div>
  );
}

function LabelAssignment(props) {
  return (
    <div className="h-screen flex flex-col justify-around">
      <div>
        <h1 className="text-center text-2xl">
          Please click confirm to print the labels.
        </h1>
      </div>
      <div className="h-3/6 overflow-y-scroll w-11/12 self-center rounded-xl">
        <LabelTable labels={props.data} />
      </div>
      <div className="flex justify-around">
        <StandardButton size="cancel" onClick={props.onCancel}>
          Cancel
        </StandardButton>
        <StandardButton size="big" onClick={props.onConfirm}>
          Confirm
        </StandardButton>
      </div>
    </div>
  );
}

// list of tagged items from Ishi
// External shipping box - large label
// Mini SST tube - small blue label
// Weight scale - large orange label
// Saliva biohazard bag - large yellow label
// Centrifuge - large green label
// Blood biohazard bag - large blue label
// blood pressure monitor - large purple label
function LabelTypes(props) {
  // const [labelingAssets, setLabelingAssets] = useState([]);
  // const getComponents = async () => {
  //   const token = await getAccessTokenSilently();
  //   const response = await axios.get(`${BACKEND}/components`, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  //   return response;
  // };

  // useEffect(() => {
  //   async function fetchData() {
  //     const res = await getComponents();
  //     var filtered_assets = res.data.map((asset) => asset.description);
  //     setLabelingAssets(filtered_assets);
  //   }
  //   fetchData();
  // }, []);

  // const { getAccessTokenSilently } = useAuth0();

  return (
    <div className="h-screen flex flex-col justify-around">
      <div>
        <h1 className="text-center text-2xl">
          Select a component to create label.
        </h1>
      </div>
      <div className="grid grid-cols-3 gap-4 place-items-center">
        {
          props.labelingAssets.map(asset => {
            return <LabelItem
                    key={asset}
                    labelType={asset}
                    handleClick={props.handleClick}
                  />
          })
        }
      </div>
      <div></div>
    </div>
  );
}

// useEffect(() => {
//   async function fetchData() {
//     // You can await here
//     const response = await MyAPI.getData(someId);
//     // ...
//   }
//   fetchData();
// }, [someId]);

function LabelSubtypes(props) {
  return (
    <div className="h-screen flex flex-col justify-around">
      <div>
        <h1 className="text-center text-2xl">
          Select the label subtype to assign to.
        </h1>
      </div>
      <div className="grid grid-cols-3 gap-4 place-items-center">
        {
          props.sku.map((s, i) => {
            return (
              <StandardButton
                key={i}
                size="skuItem"
                onClick={() => props.handleClick(s)}
              >
                {s}
              </StandardButton>
            )
          })
        }
      </div>
      <div>
        <StandardButton size="big" onClick={props.onCancel}>
          Cancel
        </StandardButton>
      </div>
    </div>
  );
}

function LabelAmounts(props) {
  return (
    <div className="h-screen flex flex-col justify-around">
      <div>
        <h1 className="text-center text-2xl">
          Select the number of labels to create.
        </h1>
      </div>
      <div className="flex flex-row justify-around">
        <StandardButton size="big" onClick={(e) => props.handleClick("1")}>
          1
        </StandardButton>
        <StandardButton size="big" onClick={(e) => props.handleClick("20")}>
          20
        </StandardButton>
        <StandardButton size="big" onClick={(e) => props.handleClick("84")}>
          84
        </StandardButton>
      </div>
    </div>
  );
}

function Labels(props) {
  const [labelType, setLabelType] = useState("");
  const [labelSubtype, setLabelSubtype] = useState("");
  const [labelList, setLabelList] = useState([]);
  const [workflowState, setWorkflowState] = useState("LABEL_TYPES");
  const { getAccessTokenSilently } = useAuth0();
  const [labelingAssets, setLabelingAssets] = useState([]);
  const [sku, setSku] = useState([]);

  const getComponents = async () => {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${BACKEND}/components`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  useEffect(() => {
    async function fetchData() {
      const res = await getComponents();
      var filtered_assets = res.data.components.map((asset) => {
        asset = asset.replace(/'/g, "\"");
        asset = JSON.parse(asset);
        return asset.description;
      });
      var filtered_sku = res.data.subtypes.map((sku) => {
        sku = sku.replace(/'/g, "\"");
        sku = JSON.parse(sku);
        return sku.id;
      });
      setLabelingAssets(filtered_assets);
      setSku(filtered_sku);
    }
    fetchData();
  }, []);

  function onClickLabelType(value) {
    setLabelType(value);
    // depending on the label, we may have to figure out its subtype
    const itemsWithSubtype = ["Kit Box"];
    if (itemsWithSubtype.includes(value)) {
      setWorkflowState("LABEL_SUBTYPES");
    } else {
      setWorkflowState("LABEL_AMOUNTS");
    }
  }

  function onClickLabelSubtype(value) {
    setLabelSubtype(value);
    setWorkflowState("LABEL_AMOUNTS");
  }

  function onScanLabelType(barcodeId) {
    setLabelType(barcodeId);
    setWorkflowState("LABEL_AMOUNTS");
  }

  const labelListMutation = useMutation(
    async (count) => {
      const body = {
        count: count,
        labelType: labelType,
        labelSubtype: labelSubtype,
      };

      const token = await getAccessTokenSilently();

      const res = await axios.post(`${BACKEND}/labels`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    },
    {
      onSuccess: (data) => {
        console.log("onSuccess, ", data);
        //makeCSV(data.labels);
        setLabelList(data.labels);
        setWorkflowState("LABEL_ASSIGNMENTS");
      },
    }
  );

  async function onClickCancelled() {
    const token = await getAccessTokenSilently();
    const body = {
      labels: labelList,
      labelType: labelType,
      labelSubtype: labelSubtype,
    };
    await axios.delete(`${BACKEND}/labels`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: body,
    });

    setWorkflowState("CANCELED");
    return;
  }

  async function onClickConfirmed() {
    const token = await getAccessTokenSilently();
    // const updateList = labelList.filter((al) => al.confirmed);
    const updateList = labelList.map((al) => {
      al.confirmed = true;
      return al;
    });
    const updateBody = {
      labels: updateList,
      labelType: labelType,
      labelSubtype: labelSubtype,
    };
    await axios.put(`${BACKEND}/labels`, updateBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // TODO:
    // trigger the printer to print labels

    setWorkflowState("CONFIRMED");
    return;
  }

  /**
   *  scan physical labels or the cancel/confirm barcodes rendered on the screen
   * @param {str} barcodeId
   */
  async function onScanPhysicalLabel(barcodeId) {
    const token = await getAccessTokenSilently();
    if (barcodeId === "CANCEL") {
      const body = { labels: labelList };
      await axios.delete(`${BACKEND}/labels`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });

      setWorkflowState("CANCELED");
      return;
    }

    if (barcodeId === "CONFIRM") {
      // const updateList = labelList.filter((al) => al.confirmed);
      const updateList = labelList.map((al) => (al.confirmed = true));
      const updateBody = { labels: updateList };
      await axios.put(`${BACKEND}/labels`, updateBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // const deleteList = labelList.filter((al) => !al.confirmed);
      // const deleteBody = { labels: deleteList };
      // await axios.delete(`${BACKEND}/labels`, {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${token}`,
      //   },
      //   data: deleteBody,
      // });

      setWorkflowState("CONFIRMED");
      return;
    }

    // The code to flag labels as physically scanned. disabled because we're just going to assume all confirmed
    //   setLabelList((labelList) => {
    //     return labelList.map((item) => {
    //       if (item.id !== barcodeId) {
    //         return item;
    //       }
    //       return {
    //         ...item,
    //         confirmed: true,
    //       };
    //     });
    //   });
  }

  function onClickOk() {
    setLabelType("");
    setLabelList([]);
    setWorkflowState("LABEL_TYPES");
  }

  function onScanOk(barcodeId) {
    if (barcodeId === "OK") {
      setLabelType("");
      setLabelList([]);
      setWorkflowState("LABEL_TYPES");
    }
  }

  let component = null;
  if (workflowState === "LABEL_TYPES") {
    getComponents();
    component = (
      <LabelTypes labelingAssets={labelingAssets} labelType={labelType} handleClick={onClickLabelType} />
    );
  } else if (workflowState === "LABEL_SUBTYPES") {
    component = (
      <LabelSubtypes
        currentLabelType={labelType}
        labelSubtype={labelSubtype}
        sku={sku}
        handleClick={onClickLabelSubtype}
      />
    );
  } else if (workflowState === "LABEL_AMOUNTS") {
    component = <LabelAmounts handleClick={labelListMutation.mutate} />;
  } else if (workflowState === "LABEL_ASSIGNMENTS") {
    component = (
      <LabelAssignment
        data={labelList}
        onScan={onScanPhysicalLabel}
        onCancel={onClickCancelled}
        onConfirm={onClickConfirmed}
      />
    );
  } else if (workflowState === "CANCELED") {
    component = <LabelCanceled data={labelList} onOk={onClickOk} />;
  } else if (workflowState === "CONFIRMED") {
    component = <LabelConfirmed data={labelList} onOk={onClickOk} />;
  }

  return (
    <>
      <div className="h-screen">{component}</div>
    </>
  );
}

export default Labels;
