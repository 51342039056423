import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

function StateConfiguration() {
  const queryClient = useQueryClient();

  const BACKEND = process.env.REACT_APP_SERVER_URL;
  const { getAccessTokenSilently } = useAuth0();

  const { data: backendState } = useQuery("stateConfig", async () => {
    const token = await getAccessTokenSilently();
    const res = await axios.get(`${BACKEND}/state_configuration`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  });

  let stateDisplay = null;
  if (backendState) {
    stateDisplay = backendState
      .sort((a, b) => a.state.localeCompare(b.state))
      .map((row) => {
        return (
          <tr key={row?.sku + row?.state}>
            <td className="text-left border-b px-8 py-2">{row?.sku}</td>
            <td className="text-left border-b px-8 py-2">{row?.state}</td>
            <td className="text-left border-b px-8 py-2">
              <select
                defaultValue={row?.destination}
                onChange={(e) =>
                  handleDestinationChange(e.target.value, row?.sku, row?.state)
                }
                className="w-full"
              >
                <option value="KIT">KIT</option>
                <option value="ADL">ADL</option>
              </select>
            </td>
          </tr>
        );
      });
  }

  stateDisplay = (
    <table className="table-fixed w-full bg-kit-light-2 ">
      <tr>
        <th className="text-left border-b px-8 py-2">SKU</th>
        <th className="text-left border-b px-8 py-2">State</th>
        <th className="text-left border-b px-8 py-2">Destination</th>
      </tr>
      {stateDisplay}
    </table>
  );

  const stateMutation = useMutation(
    async ({ sku, state, destination }) => {
      const body = {
        sku: sku,
        state: state,
        destination: destination,
      };

      const token = await getAccessTokenSilently();

      const res = await axios.put(`${BACKEND}/state_configuration`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("stateConfig");
      },
    }
  );

  function handleDestinationChange(destination, sku, state) {
    stateMutation.mutate({ sku, state, destination });
  }

  return (
    <div className="py-8">
      <div className="h-full flex flex-col justify-around">
        <div>
          <h2>{stateDisplay}</h2>
        </div>
      </div>
    </div>
  );
}

export default StateConfiguration;
