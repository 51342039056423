import React from "react";
import Table from "./table";

function LabelTable(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: "id",
        accessor: (row) => row.id,
      },
      {
        Header: "type",
        accessor: (row) => row.type,
      },
      {
        Header: "subtype",
        accessor: (row) => row.subtype,
      },

      // {
      //   Header: "uses",
      //   accessor: (row) => row.uses,
      // },
      // {
      //   Header: "max_uses",
      //   accessor: (row) => row.max_uses,
      // },
      // {
      //   Header: "confirmed",
      //   accessor: (row) =>
      //     row.confirmed ? (
      //       <div className="text-green-700">confirmed</div>
      //     ) : (
      //       <div className="text-red-700">unconfirmed</div>
      //     ),
      // },
    ],
    []
  );

  return <Table columns={columns} data={props.labels} />;
}

export default LabelTable;
