import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React, { useState } from 'react';
import Loading from '../components/loading';
import BarcodeInput from '../components/barcodeInput';
import StandardButton from '../components/standardButton';
import { CSVLink } from 'react-csv';

const BACKEND = process.env.REACT_APP_SERVER_URL;

function TableHead() {
  return (
    <thead>
      <tr>
        <TableHeader>Box ID</TableHeader>
      </tr>
    </thead>
  );
}

function TableHeader({ children }) {
  return (
    <th style={{ border: '1px solid black', padding: '10px' }}>{children}</th>
  );
}

function TableRow({ bid }) {
  return (
    <tr id={bid} style={{ border: '1px solid black', textAlign: 'center' }}>
      <TableCell>{bid}</TableCell>
    </tr>
  );
}

function TableCell({ children }) {
  return (
    <td style={{ border: '1px solid black', padding: '10px' }}>{children}</td>
  );
}

function AdlExport() {
  const { getAccessTokenSilently } = useAuth0();
  const [curBids, setCurBids] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [fetching, setFetching] = useState(false);

  const fileName = new Date().toLocaleDateString().replace(/\//g, '-') + '.csv';

  const handleScan = async (boxId) => {
    setFetching(true);
    const res = await fetchMedplumData(boxId);
    if (res === '') {
      setFetching(false);
      setCurBids([...curBids, boxId]);
    } else {
      setFetching(false);
      alert("Please don't ship this kit to ADL: " + boxId + '\nReason: ' + res);
    }
  };

  const fetchMedplumData = async (boxId) => {
    try {
      const token = await getAccessTokenSilently();
      var { data } = await axios.get(`${BACKEND}/lab_data?box_id=${boxId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.sku && data.sku !== 'BDO-5A-RO-BY') {
        return 'Not a Ro-Body Kit';
      }

      const specimens = await axios.get(
        `${BACKEND}/lab_data?specimen_id=${data.specimens[0]}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data && specimens.data.collection?.collectedDateTime) {
        setCsvData((prev) => [
          ...prev,
          {
            Name: data.patient.name,
            DOB: data.patient.birthDate,
            PID: data.barcodeId,
            Gender: data.patient.gender,
            Collection: specimens.data.collection?.collectedDateTime || '',
          },
        ]);
      } else {
        return 'Missing Collection Date/Time';
      }
      return '';
    } catch (err) {
      console.log(err.response);
      return 'err.response';
      //alert(err.response.data);
    }
  };

  // Update orderDetail and status of each SR
  async function updateMedplumData() {
    try {
      const token = await getAccessTokenSilently();
      console.log(curBids);
      const res = await Promise.all(
        curBids.map(async (bid) => {
          var { data } = await axios.put(`${BACKEND}/adl_export`, bid, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
          return data;
        })
      );

      // highlight each successful updated row
      res.forEach((object) => {
        document.getElementById(object.bid).style.backgroundColor = '#198754';
      });

      if (res.length === curBids.length) {
        alert('SUCCESS! All Box ID data updated in Medplum!');
      } else {
        alert(
          'Seems at least one bid failed to update in Medplum. Please contact engineers.'
        );
      }
    } catch (err) {
      console.log(err.response);
    }
  }

  const clearCurrentBid = () => {
    // clear current bids
    setCurBids([]);
    setCsvData([]);
  };

  return (
    <div className='h-screen'>
      <div className='flex flex-col space-y-5 justify-around'>
        <div className='w-4/12 self-center'>
          <h1 className='text-center text-2xl font-bold'>
            Enter box ID for samples shipping to ADL
          </h1>
        </div>
        {!fetching ? (
          <BarcodeInput
            onScan={handleScan}
            placeholder='Scan or enter box ID manually here'
            ignoreSecondId={true}
          />
        ) : null}

        {curBids.length === csvData.length ? (
          <div className='grid grid-cols-8 gap-4'>
            <div>
              <CSVLink data={csvData} filename={fileName}>
                <StandardButton size='big'>Download CSV</StandardButton>
              </CSVLink>
            </div>
            <div>
              <StandardButton size='big' onClick={updateMedplumData}>
                Send to ADL
              </StandardButton>
            </div>
            <div>
              <StandardButton size='big' onClick={clearCurrentBid}>
                Clear
              </StandardButton>
            </div>
          </div>
        ) : (
          <Loading />
        )}
        <table
          style={{ border: '1px solid black', borderCollapse: 'collapse' }}
        >
          <TableHead />
          <tbody>
            {[...curBids].reverse().map((bid) => (
              <TableRow key={bid} bid={bid} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdlExport;
