import React, { useEffect, useRef, useState } from "react";

/**
 * input field that runs callback fn when enter is pressed.
 * since usb barcode scanners just type some text and press enter, we can leverage that functionality
 * + the callback as a
 *
 * uses ref to forcibly refocus into the input field
 */
function BarcodeInput(props) {
  const [barcodeId, setBarcodeId] = useState("");
  const ignoreSecondId = props.ignoreSecondId || undefined;

  const ref = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      ref.current && ref.current.focus();
    }, 100);

    return () => clearInterval(interval);
  }, []);

  function handleKeyDown(e) {
    // keycode 13 is enter key
    // barcode scanners can be configured to input newline at the end of a scan
    if (e.keyCode === 13) {
      props.onScan(barcodeId);
      setBarcodeId("");
      return;
    }
  }

  return (
    <div className="flex justify-around basis-16">
      <input
        className={
          props.className +
          " bg-kit-light-2 appearance-none w-1/3 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        }
        onChange={(e) => {
          var bid = e.target.value.replace("PBK", "");
          if (ignoreSecondId && bid.length > 6) {
              bid = bid.substring(0,6);
          }          
          setBarcodeId(bid);
        }}
        onKeyDown={(e) => handleKeyDown(e)}
        type="text"
        value={barcodeId}
        ref={ref}
        placeholder={props.placeholder}
      ></input>
    </div>
  );
}

export default BarcodeInput;
