import React from 'react';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import MenuItem from '../components/menuItem';
// import MachineResults from './machineResults';
// import Reports from './reports';
import LabData from './labData';
import SkuList from './skuList';
import AdlExport from './adl-export';
import { ResultsView } from '../components/resultsView';
import MedplumSignIn from './medplumSignIn';
import { MedplumClient } from '@medplum/core';
import { MedplumProvider } from '@medplum/react';
import { MantineProvider } from '@mantine/core';
import KitPendingOrdersDashboard from './kit-pending-observation-dashboard';
import ValidationTable from './validationTable';

function Lab() {
  const medplum = new MedplumClient({ autoBatchTime: 500, cacheTime: 60000 });

  return (
    <BrowserRouter basename="/lab">
      <Switch>
        <Route path="/" exact>
          <div className="h-screen overflow-hidden">
            <h1 className="text-4xl font-bold p-8">Welcome</h1>
            <div className="flex flex-row justify-start space-x-8 p-8">
              {/* Non critical: Was built during b2b times Ro currently deals with reports
              in the Diagnostic Service. */}
              {/* <Link className="no-underline" to="/reports">
                <MenuItem title="Reports" />
              </Link> */}
              {/* (Non critical): Allows for the ingestion of results from outside labs
              for analytes that cannot be tested in-house. All analytes for Ro are
              currently being handled in house and this feature is not being used. */}
              {/* <Link className="no-underline" to="/machine-results">
                <MenuItem title="Machine results" />
              </Link> */}
              <Link className="no-underline" to="/lab-data">
                <MenuItem title="Lab Data" />
              </Link>
              <Link className="no-underline" to="/sku-list">
                <MenuItem title="SKU List" />
              </Link>
              <Link className="no-underline" to="/adl-export">
                <MenuItem title="ADL Export" />
              </Link>
              <Link className="no-underline" to="/pending-repairs">
                <MenuItem title="Pending Repairs" />
              </Link>
              <Link
                className="no-underline"
                to="/kit-pending-observation-dashboard"
              >
                <MenuItem title="Kit Pending Observation Dashboard" />
              </Link>
            </div>
          </div>
        </Route>
        {/* Non critical: Was built during b2b times Ro currently deals with reports
        in the Diagnostic Service. */}
        {/* <Route path="/reports">
          <Reports />
        </Route> */}

        {/* (Non critical): Allows for the ingestion of results from outside labs
        for analytes that cannot be tested in-house. All analytes for Ro are
        currently being handled in house and this feature is not being used. */}
        {/* <Route path="/machine-results">
          <MachineResults />
        </Route> */}

        <Route path="/lab-data">
          <LabData />
        </Route>
        <Route path="/sku-list">
          <SkuList />
        </Route>
        <Route path="/adl-export">
          <AdlExport />
        </Route>
        <MedplumProvider medplum={medplum}>
          <MantineProvider withGlobalStyles withNormalizeCSS>
            <Route path="/pending-repairs">
              <MedplumSignIn role="lab" component={ValidationTable} />
            </Route>
            <Route path="/ServiceRequest/:id">
              <ResultsView role="lab" />
            </Route>
            <Route path="/kit-pending-observation-dashboard">
              <MedplumSignIn role="lab" component={KitPendingOrdersDashboard} />
            </Route>
          </MantineProvider>
        </MedplumProvider>
      </Switch>
    </BrowserRouter>
  );
}

export default Lab;
