import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "react-query";
import LogoutButton from "../components/logout-button";
import Loading from "../components/loading";
import axios from "axios";

function LabAutomation() {
  const [barcodeId, setBarcodeId] = useState(0);
  const [templateDocument, setTemplateDocument] = useState("control_panel");
  const [machineType, setMachineType] = useState("biorad");
  const [fileToUpload, setFileToUpload] = useState(null);

  const BACKEND = process.env.REACT_APP_SERVER_URL;
  const { getAccessTokenSilently } = useAuth0();

  const medplumMutation = useMutation(async ({ e, barcodeId }) => {
    e.preventDefault();
    const body = {
      barcode_id: barcodeId,
    };

    const token = await getAccessTokenSilently();

    const res = await axios.post(`${BACKEND}/update_medplum`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  });

  const documentMutation = useMutation(
    async ({ e, barcodeId, templateDocument }) => {
      e.preventDefault();
      const body = {
        barcode_id: barcodeId,
        template_document: templateDocument,
      };

      const token = await getAccessTokenSilently();

      const res = await axios.post(`${BACKEND}/build_document`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    }
  );

  const approveMutation = useMutation(async ({ e, barcodeId }) => {
    e.preventDefault();
    const body = {
      barcode_id: barcodeId,
    };

    const token = await getAccessTokenSilently();

    const res = await axios.post(`${BACKEND}/approve_document`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  });

  const orderMutation = useMutation(async ({ e, barcodeId }) => {
    e.preventDefault();

    const params = new URLSearchParams({ barcode_id: barcodeId }).toString();

    const token = await getAccessTokenSilently();

    const res = await axios.get(`${BACKEND}/order_url?${params}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  });

  const labelMutation = useMutation(async ({ e, barcodeId }) => {
    e.preventDefault();
    const body = {
      barcode_id: barcodeId,
    };

    const token = await getAccessTokenSilently();

    const res = await axios.post(`${BACKEND}/shipping_label`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  });

  const parseMutation = useMutation(async ({ e }) => {
    e.preventDefault();
    const token = await getAccessTokenSilently();
    let data = new FormData();
    data.append("machine_type", machineType);
    data.append(
      "file",
      fileToUpload,
      `${templateDocument}-${machineType}-${Date.now()}.csv`
    );

    const res = await axios.post(`${BACKEND}/batch_parse`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  });

  return (
    <div className="container">
      <LogoutButton />
      <div className="explainer">
        <ul>
          <li>Go to the patient's order on medplum.</li>
          <li>
            Upload files from labs or machines.
            <ul>
              <li>for valley clinical only upload the page of the patient.</li>
            </ul>
          </li>
          <li>
            Insert a barcode id below and click update medplum. It will take a
            few seconds to complete.
          </li>
          <li>
            Check the medplum form. Correct for any errors or missing fields.
          </li>
          <li>Click build doc.</li>
        </ul>
      </div>
      <div className="forms">
        <div>
          <form>
            <div className="inputs">
              <label>barcode id: </label>
              <input
                onChange={(e) => setBarcodeId(e.target.value)}
                type="text"
              ></input>
            </div>
            <div className="inputs">
              <label>output template: </label>
              <select onChange={(e) => setTemplateDocument(e.target.value)}>
                <option value="control_panel">control panel</option>
                <option value="valley_clinical">valley clinical</option>
              </select>
            </div>
            <div className="buttons">
              <button onClick={(e) => orderMutation.mutate({ e, barcodeId })}>
                medplum url
              </button>
              <div>
                <div>{orderMutation.isLoading ? <Loading /> : ""}</div>
                <div>
                  {orderMutation.isError ? (
                    <div>
                      <div>Error</div>
                      <div>{orderMutation.error?.response?.data?.code}</div>
                      <div>{orderMutation.error?.response?.data?.name}</div>
                      <div>
                        {orderMutation.error?.response?.data?.description}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {orderMutation.data ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={orderMutation.data.url}
                    >
                      {orderMutation.data.url}
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="buttons">
              <button onClick={(e) => labelMutation.mutate({ e, barcodeId })}>
                make shipping labels
              </button>
              <div>{labelMutation.isLoading ? <Loading /> : ""}</div>
              <div>
                {labelMutation.isError ? (
                  <div>
                    <div>Error</div>
                    <div>{labelMutation.error?.response?.data?.code}</div>
                    <div>{labelMutation.error?.response?.data?.name}</div>
                    <div>
                      {labelMutation.error?.response?.data?.description}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div>
                {labelMutation.data ? (
                  <>
                    <div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={labelMutation.data.label}
                      >
                        label
                      </a>
                    </div>
                    <div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={labelMutation.data.returnLabel}
                      >
                        return label
                      </a>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="buttons">
              <button onClick={(e) => medplumMutation.mutate({ e, barcodeId })}>
                update medplum form
              </button>
              <div>{medplumMutation.isLoading ? <Loading /> : ""}</div>
              <div>
                {medplumMutation.isError ? (
                  <div>
                    <div>Error</div>
                    <div>{medplumMutation.error?.response?.data?.code}</div>
                    <div>{medplumMutation.error?.response?.data?.name}</div>
                    <div>
                      {medplumMutation.error?.response?.data?.description}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div>
                {medplumMutation.data ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={medplumMutation.data.url}
                  >
                    {medplumMutation.data.url}
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="buttons">
              <button
                onClick={(e) =>
                  documentMutation.mutate({ e, barcodeId, templateDocument })
                }
              >
                build google doc
              </button>
              <div>{documentMutation.isLoading ? <Loading /> : ""}</div>
              <div>
                {documentMutation.isError ? (
                  <div>
                    <div>Error</div>
                    <div>{documentMutation.error?.response?.data?.code}</div>
                    <div>{documentMutation.error?.response?.data?.name}</div>
                    <div>
                      {documentMutation.error?.response?.data?.description}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div>
                {documentMutation.data ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={documentMutation.data.url}
                  >
                    {documentMutation.data.url}
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="buttons">
              <button
                onClick={(e) =>
                  approveMutation.mutate({ e, barcodeId, templateDocument })
                }
              >
                approve google doc
              </button>
              <div>{approveMutation.isLoading ? <Loading /> : ""}</div>
              <div>
                {approveMutation.isError ? (
                  <div>
                    <div>Error</div>
                    <div>{approveMutation.error?.response?.data?.code}</div>
                    <div>{approveMutation.error?.response?.data?.name}</div>
                    <div>
                      {approveMutation.error?.response?.data?.description}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div>
                {approveMutation.data ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={approveMutation.data.url}
                  >
                    {approveMutation.data.url}
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="buttons">
              <label>result file type: </label>
              <select onChange={(e) => setMachineType(e.target.value)}>
                <option value="biorad">biorad</option>
                <option value="beckman">beckman</option>
              </select>
              <input
                type="file"
                onChange={(e) => setFileToUpload(e.target.files[0])}
              />
              <button onClick={(e) => parseMutation.mutate({ e })}>
                upload result
              </button>
              <div>{parseMutation.isLoading ? <Loading /> : ""}</div>
              <div>
                {parseMutation.isError ? (
                  <div>
                    <div>Error</div>
                    <div>{parseMutation.error?.response?.data?.code}</div>
                    <div>{parseMutation.error?.response?.data?.name}</div>
                    <div>
                      {parseMutation.error?.response?.data?.description}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div>
                {parseMutation.data ? (
                  <table
                    border="1"
                    style={{
                      borderCollapse: "collapse",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Barcode ID</th>
                        <th>Medplum Order ID</th>
                        <th>Status</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {parseMutation.data.map((res) => (
                        <tr key={res.barcode_id}>
                          <td>{res.barcode_id}</td>
                          <td>{res.medplum_order_id}</td>
                          <td>{res.status}</td>
                          <td>{res.error}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LabAutomation;
