import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Link, Route, Switch } from 'react-router-dom';
import './App.css';
import KitLogo from './assets/svg/logo-kit.svg';
import ProtectedRoute from './auth/protected-route';
import LogoutButton from './components/logout-button';
import MenuItem from './components/menuItem';
import CX from './views/cx';
import Lab from './views/lab';
import LabAutomation from './views/lab-automation';
import Warehouse from './views/warehouse';

const LAB_ACCESS_ROLES = ['lab', 'admin'];
const WAREHOUSE_ACCESS_ROLES = ['warehouse-phx', 'warehouse-sfo', 'admin'];
const CX_ACCESS_ROLES = ['cx', 'admin'];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0,
    },
  },
});

const App = () => {
  const { user } = useAuth0();
  const userRoles = user['kit/roles'];

  return (
    <QueryClientProvider client={queryClient}>
      <div className='flex justify-between'>
        <div className='h-8 self-center col-span-2'>
          <Link to='/'>
            <img
              alt='kit logo'
              className='object-contain h-full'
              src={KitLogo}
            />
          </Link>
        </div>
        <div className='col-span-1 col-end-13 h-auto p-2'>
          <LogoutButton />
        </div>
      </div>
      <Switch>
        <Route path='/' exact>
          <div className='h-screen overflow-hidden'>
            <h1 className='text-4xl font-bold p-8'>Welcome</h1>
            <div className='flex flex-row justify-start space-x-8 p-8'>
              {userRoles.find((role) => LAB_ACCESS_ROLES.includes(role)) ? (
                <Link className='no-underline' to='/lab'>
                  <MenuItem title='Lab' />
                </Link>
              ) : (
                <></>
              )}
              {userRoles.find((role) =>
                WAREHOUSE_ACCESS_ROLES.includes(role)
              ) ? (
                <Link className='no-underline' to='/warehouse'>
                  <MenuItem title='Warehouse' />
                </Link>
              ) : (
                <></>
              )}
              {userRoles.find((role) => CX_ACCESS_ROLES.includes(role)) ? (
                <Link className='no-underline' to='/cx'>
                  <MenuItem title='CX' />
                </Link>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Route>
        <ProtectedRoute
          allowedRoles={['admin', 'lab']}
          path='/lab'
          component={Lab}
        />
        <ProtectedRoute
          allowedRoles={[
            'admin',
            'warehouse',
            'warehouse-phx',
            'warehouse-sfo',
          ]}
          path='/warehouse'
          component={Warehouse}
        />
        <ProtectedRoute
          allowedRoles={['admin', 'cx']}
          path='/cx'
          component={CX}
        />
        <ProtectedRoute path='/lab-automation' component={LabAutomation} />
      </Switch>
    </QueryClientProvider>
  );
};

export default withAuthenticationRequired(App);
