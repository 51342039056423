import React from 'react';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import MenuItem from '../components/menuItem';
import { ResultsView } from '../components/resultsView';
import MedplumSignIn from './medplumSignIn';
import { MedplumClient } from '@medplum/core';
import { MedplumProvider } from '@medplum/react';
import { MantineProvider } from '@mantine/core';
import ValidationTable from './validationTable';

function CX() {
  const medplum = new MedplumClient({ autoBatchTime: 500, cacheTime: 60000 });

  return (
    <BrowserRouter basename='/cx'>
      <Switch>
        <Route path='/' exact>
          <div className='h-screen overflow-hidden'>
            <h1 className='text-4xl font-bold p-8'>Welcome</h1>
            <div className='flex flex-row justify-start space-x-8 p-8'>
              <Link className='no-underline' to='/pending-repairs'>
                <MenuItem title='Pending Repairs' />
              </Link>
            </div>
          </div>
        </Route>
        <MedplumProvider medplum={medplum}>
          <MantineProvider withGlobalStyles withNormalizeCSS>
            <Route path='/pending-repairs'>
              <MedplumSignIn component={ValidationTable} role='cx' />
            </Route>
            <Route path='/ServiceRequest/:id'>
              <ResultsView role='cx' />
            </Route>
          </MantineProvider>
        </MedplumProvider>
      </Switch>
    </BrowserRouter>
  );
}

export default CX;
