import React from "react";
import { useTable } from "react-table";

function Table(props) {
  const tableInstance = useTable({
    columns: props.columns,
    data: props.data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <table
      className="table-fixed w-full bg-kit-light-2 "
      {...getTableProps()}
    >
      <thead>
        <tr>
          {headerGroups.map((hg) =>
            hg.headers.map((column) => (
              <th
                className="text-left border-b px-8 py-8"
                {...column.getHeaderProps()}
              >
                {column.render("Header")}
              </th>
            ))
          )}
        </tr>
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    className="text-left border-b px-8 py-8"
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;
