import React from "react";
import Table from "./table";

function BoxAssignmentTable(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Asset id",
        accessor: (row) => row.id,
      },
      {
        Header: "Category",
        accessor: (row) =>
          !row.id ? (
            <div className="text-red-700">{row.category}</div>
          ) : (
            <div className="text-green-700">{row.category}</div>
          ),
      },
      {
        Header: "Uses",
        accessor: (row) => ((row.uses || row.uses === 0) ? row.uses : null),
      },
    ],
    []
  );

  // an array of required assets straight from our db. map to the table format
  let displayedAssets = props.requiredAssets
    .map((ra) => {
      let new_ra = {
        id: null,
        category: ra,
        uses: null,
      };

      return new_ra;
    })
    // merge assigned asset into required assets
    .map((ra) => {
      const targetAsset = props.assignedAssets.find(
        (aa) => aa.category === ra.category
      );
      if (targetAsset) {
        ra.id = targetAsset.id;
        ra.uses = targetAsset.uses;
      }
      return ra;
    });

  return <Table columns={columns} data={displayedAssets} />;
}

export default BoxAssignmentTable;
