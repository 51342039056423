import { ICellEditorComp } from 'ag-grid-community';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import React from 'react';

export default forwardRef<ICellEditorComp>((props, forwardedRef) => {
  const [date, setDate] = useState<Date | null>(null);
  const refInput = useRef<HTMLInputElement>(null);
  useImperativeHandle(forwardedRef, () => ({
    //*********************************************************************************
    //          METHODS REQUIRED BY AG-GRID
    //*********************************************************************************
    getValue() {
      //ag-grid will call us here when in need to check what the current date value is hold by this
      //component.
      return date;
    },
    getGui() {
      return refInput.current!;
    },
  }));

  // inlining styles to make simpler the component
  return (
    <input
      ref={refInput}
      type="date"
      onChange={(e) => {
        const date = parseISOLocal(e.target.value);
        setDate(date);
      }}
    />
  );
});

function parseISOLocal(s: string) {
  var b = s.split(/\D/).map((s) => parseInt(s));
  return new Date(b[0], b[1] - 1, b[2]);
}
