import React from "react";
import QRCode from "qrcode.react";
import Table from "./table";
import { medplumUrl, parse_name } from "../utils";

function OrderTable(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: "OrderId",
        accessor: (row) => (
          <a target="_blank" rel="noreferrer" href={medplumUrl(row.id)}>
            {row.id}
          </a>
        ),
      },
      {
        Header: "Name",
        accessor: (row) => row?.nameDisplayed,
      },
      {
        Header: "Kit Type",
        accessor: (row) => row?.kitType,
      },
      {
        Header: "SKU",
        accessor: (row) => row?.sku,
      },
      {
        Header: "Carrier",
        accessor: (row) => row?.insuranceCarrierName,
      },
      {
        Header: "QR Code",
        accessor: (row) => <QRCode size={128} value={row.id} />,
      },
    ],
    []
  );

  return <Table columns={columns} data={props.orders} />;
}

export default OrderTable;
